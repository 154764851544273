import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  SEARCH_PRODUCTS_START_TYPE,
  SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
  SEARCH_PRODUCTS_FAILED_TYPE,
} from "./actions";
import {
  PRODUCTS_FETCH_START_TYPE,
  PRODUCTS_FETCH_SUCCESSFUL_TYPE,
  PRODUCTS_FETCH_FAILED_TYPE,
  CLEAR_SEARCH_PRODUCTS_TYPE,
} from "../../views/Products/actions";

interface ProductsState {
  data: List<any>;
  searchedData: List<any>;
  isSearchingProducts: boolean;
  isFetchingProducts: boolean;
}

const initialProductsState: ProductsState = {
  data: List<any>(),
  searchedData: List<any>(),
  isFetchingProducts: false,
  isSearchingProducts: false,
};

// @ts-ignore
function reducer(state = initialProductsState, { type, payload }) {
  switch (type) {
    case SEARCH_PRODUCTS_START_TYPE: {
      return {
        ...state,
        isSearchingProducts: true,
      };
    }

    case SEARCH_PRODUCTS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.searchedData = fromJS(responseData);
      }

      return {
        ...state,
        isSearchingProducts: false,
      };
    }

    case SEARCH_PRODUCTS_FAILED_TYPE: {
      return {
        ...state,
        isSearchingProducts: false,
      };
    }

    case PRODUCTS_FETCH_START_TYPE: {
      return {
        ...state,
        isFetchingProducts: true,
      };
    }

    case PRODUCTS_FETCH_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = fromJS(responseData);
      }

      return {
        ...state,
        isFetchingProducts: false,
      };
    }

    case PRODUCTS_FETCH_FAILED_TYPE: {
      return {
        ...state,
        isFetchingProducts: false,
      };
    }

    case CLEAR_SEARCH_PRODUCTS_TYPE: {
      state.searchedData = List();

      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
