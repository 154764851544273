import {
  TableCell,
  TableSortLabel,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import React, { useCallback, useRef } from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  RowMouseEventHandlerParams,
  Table as VirtualizedTable,
  TableCellProps,
  TableHeaderProps,
  InfiniteLoader,
  IndexRange,
} from "react-virtualized";
import { SORT_ORDER_TYPE } from "../constants";

import styles from "./styles";

export interface Row {
  index: number;
}

export interface Columns {
  label: string;
  width: number;
  date?: boolean;
  dataKey: string;
  numeric?: boolean;
}

export interface TableBodyProps extends WithStyles<typeof styles> {
  rowCount: number;
  remainingRows?: number;
  columns: Columns[];
  sortOrderKey?: string;
  sortOrder?: SORT_ORDER_TYPE;
  hasTableHeader?: boolean;
  tableRowHeight?: number;
  tableHeaderRowHeight: number;
  rowGetter: <T>(row: Row) => T;
  isRowLoaded?: (row: Row) => boolean;
  loadMoreRows?: (params: IndexRange) => any;
  onRequestSort?: (columnKey: string) => void;
  onRowClick?: (info: RowMouseEventHandlerParams) => void;
  determineCellContent: (
    values: TableCellProps
  ) => {
    cellContent: JSX.Element | JSX.Element[] | string;
    cellClass?: string;
  };
}

const TableBody = React.memo((props: TableBodyProps) => {
  const {
    classes,
    columns,
    sortOrder = "asc",
    onRowClick,
    isRowLoaded,
    loadMoreRows,
    rowCount,
    remainingRows,
    sortOrderKey,
    onRequestSort,
    tableRowHeight,
    hasTableHeader,
    determineCellContent,
    tableHeaderRowHeight = 48,
    ...restOfTableProps
  } = props;

  const _cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: tableRowHeight || 150,
    })
  ).current;

  /**
   *
   */
  const _memoizedHeaderRenderer = useCallback(
    ({ label, columnIndex }: TableHeaderProps & { columnIndex: number }) => {
      const { tableHeaderRowHeight, columns } = props;
      const { dataKey, date, numeric } = columns[columnIndex];

      return (
        <TableCell
          component="div"
          variant="head"
          style={{ height: tableHeaderRowHeight }}
          className={clsx(
            classes.tableCell,
            classes.tableHeaderCell,
            classes.flexContainer,
            classes.noClick
          )}
        >
          {(numeric || date) && onRequestSort ? (
            <TableSortLabel
              direction={sortOrderKey === dataKey ? sortOrder : "asc"}
              active={sortOrderKey === dataKey}
              onClick={() => onRequestSort(dataKey)}
            >
              {label}
            </TableSortLabel>
          ) : (
            label
          )}
        </TableCell>
      );
    },
    [onRequestSort, sortOrder, sortOrderKey]
  );

  /**
   *
   */
  const _memoizedGetRowClassName = useCallback(
    () =>
      clsx(classes.tableRow, classes.flexContainer, {
        [classes.tableRowHover]: onRowClick !== null,
      }),
    [classes.tableRow, classes.flexContainer, classes.tableRowHover, onRowClick]
  );

  /**
   *
   */
  const _memoizedCellRenderer = useCallback((cellValues: TableCellProps) => {
    let cellContent, cellClass;
    const { cellData, columnIndex, dataKey, parent, rowIndex } = cellValues;

    if (determineCellContent) {
      ({ cellContent, cellClass } = determineCellContent(cellValues));
    }

    return (
      <CellMeasurer
        cache={_cache}
        key={dataKey}
        parent={parent}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
      >
        <TableCell
          component="div"
          variant="body"
          className={clsx(classes.tableCell, classes.flexContainer, cellClass, {
            [classes.noClick]: onRowClick == null,
          })}
        >
          {!isEmpty(cellContent) ? cellContent : cellData}
        </TableCell>
      </CellMeasurer>
    );
  }, []);

  /**
   *
   */
  const _memoizedColumnGetter = useCallback(
    () =>
      columns.map(({ dataKey, ...other }, index) => (
        <Column
          key={dataKey}
          dataKey={dataKey}
          cellRenderer={_memoizedCellRenderer}
          className={classes.flexContainer}
          headerRenderer={(headerProps) =>
            _memoizedHeaderRenderer({
              ...headerProps,
              columnIndex: index,
            })
          }
          {...other}
        />
      )),
    [classes.flexContainer, _memoizedCellRenderer, _memoizedHeaderRenderer]
  );

  if (loadMoreRows && isRowLoaded && typeof remainingRows === "number") {
    const infiniteLoaderRowsCount = remainingRows > 1 ? rowCount + 1 : rowCount;
    const tableRowsCount = remainingRows > 1 ? rowCount - 1 : rowCount;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            rowCount={infiniteLoaderRowsCount}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
          >
            {({ onRowsRendered, registerChild }) => (
              <VirtualizedTable
                width={width}
                style={undefined}
                ref={registerChild}
                onRowClick={onRowClick}
                rowCount={tableRowsCount}
                className={classes.table}
                rowHeight={_cache.rowHeight}
                onRowsRendered={onRowsRendered}
                deferredMeasurementCache={_cache}
                headerHeight={tableHeaderRowHeight}
                rowClassName={_memoizedGetRowClassName}
                height={hasTableHeader ? height - 105 : height - 57}
                {...restOfTableProps}
              >
                {_memoizedColumnGetter()}
              </VirtualizedTable>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          width={width}
          style={undefined}
          onRowClick={onRowClick}
          className={classes.table}
          rowHeight={_cache.rowHeight}
          deferredMeasurementCache={_cache}
          headerHeight={tableHeaderRowHeight}
          rowCount={rowCount}
          rowClassName={_memoizedGetRowClassName}
          height={hasTableHeader ? height - 105 : height - 57}
          {...restOfTableProps}
        >
          {_memoizedColumnGetter()}
        </VirtualizedTable>
      )}
    </AutoSizer>
  );
});

export default withStyles(styles)(TableBody);
