import {
  Button,
  CardHeader,
  Grid,
  Paper,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { actionSignInAccount } from "./actions";
import styles from "./styles";
import { ErrorSnackbarContext } from "../App";

interface ISignInProps extends WithStyles<typeof styles> {}

const SignInSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

function SignIn({ classes }: ISignInProps) {
  const dispatch = useDispatch();

  return (
    <>
      <ErrorSnackbarContext.Consumer>
        {({ toggleAlerts }) => (
          <Formik
            key="SignIn"
            enableReinitialize
            validationSchema={SignInSchema}
            validateOnChange={false}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values) => {
              // @ts-ignore
              dispatch(actionSignInAccount(values.email, values.password)).then(
                (result: any) => {
                  const { status } = result;

                  if (status === 200) {
                    window.location.href = "/";
                  } else {
                    toggleAlerts("Failed to Sign In.", true);
                  }
                },
                () => {
                  toggleAlerts("Failed to Sign In.", true);
                }
              );
            }}
          >
            {({ errors, values, handleSubmit, handleChange }) => (
              <Grid container>
                <div className={classes.root}>
                  <Paper
                    variant={"outlined"}
                    square
                    className={classes.rootPaper}
                  >
                    <CardHeader title="Sign In" />

                    <Grid container spacing={2}>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Email"
                          placeholder="Email"
                          variant="outlined"
                          name="email"
                          error={!!errors.email}
                          helperText={errors.email}
                          defaultValue={values.email}
                          value={values.email}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Password"
                          placeholder="Password"
                          variant="outlined"
                          name="password"
                          type="password"
                          error={!!errors.password}
                          helperText={errors.password}
                          defaultValue={values.password}
                          value={values.password}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleSubmit()}
                        >
                          Sign In
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </Grid>
            )}
          </Formik>
        )}
      </ErrorSnackbarContext.Consumer>
    </>
  );
}

export default withStyles(styles)(SignIn);
