import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Select,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  AssignmentInd as AssignIcon,
  CheckCircle as CheckIcon,
  Close as CloseIcon,
  Textsms as MessageIcon,
} from "@material-ui/icons";
import { format, getTime } from "date-fns";
import isEmpty from "lodash.isempty";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Index,
  RowMouseEventHandlerParams,
  TableCellProps,
} from "react-virtualized";

import { ErrorSnackbarContext } from "../../../App";
import { sortTableWithKey, Table } from "../../../common";
import {
  actionSelectOrderRow,
  actionUnSelectAllOrders,
} from "../../Orders/actions";
import {
  actionFetchDispatchDrivers,
  actionReassignOrderToDriver,
  actionUnSelectAllDrivers,
  actionMarkOrderComplete,
} from "../actions";
import { CancelDelivery, OrderMessages } from "../Dialogs";
import { OrdersActiveColumns } from "../helpers";
import styles from "../styles";
import { zonedTimeToUtc } from "date-fns-tz";

interface IOrdersCurrentlyDispatchedProps extends WithStyles<typeof styles> {}

function OrdersCurrentlyDispatched({
  classes,
}: IOrdersCurrentlyDispatchedProps) {
  const ordersActiveTableColumns = useRef(OrdersActiveColumns);
  const errorSnackbarContext = useContext(ErrorSnackbarContext);
  const dispatch = useDispatch();

  const [selectedDriver, setSelectedDriver] = useState<string | undefined>(
    undefined
  );

  const [selectedDeliveredDate, setSelectedDeliveredDate] = useState<
    string | null
  >(null);

  const [showCancelOrderDialog, setCancelOrderDialog] = useState<{
    orderNumber: string;
    orderId: string;
  } | null>(null);

  const [showOrderMessagesDialog, setOrderMessagesDialog] = useState<{
    orderId: string;
  } | null>(null);

  useEffect(
    () => () => {
      dispatch(actionUnSelectAllOrders());
      dispatch(actionUnSelectAllDrivers());
    },
    []
  );

  const availableDrivers = useSelector((state: any) => {
    const driversList = state.dispatch.driversData;

    if (driversList.size) {
      return driversList.filter(
        (account: any) => account.get("email") && account.get("isDriving")
      );
    }

    return driversList;
  });

  const activeOrders = useSelector((state: any) => {
    const ordersList = state.orders.data;
    const selectedAvailableDrivers = availableDrivers.filter((driver: any) =>
      driver.get("selected")
    );

    const selectedAvailableDriversIds: string[] = [];

    if (selectedAvailableDrivers.size) {
      selectedAvailableDrivers.forEach((driver: any) =>
        selectedAvailableDriversIds.push(driver.get("id"))
      );
    }

    return ordersList.filter((order: any) => {
      const isActive = order.get("isActive") && order.get("isDispatched");

      if (selectedAvailableDrivers.size) {
        return (
          isActive &&
          selectedAvailableDriversIds.includes(order.getIn(["driver", "id"]))
        );
      }

      return isActive;
    });
  });

  const { sortedList: sortedOrders } = sortTableWithKey(
    activeOrders,
    "receivedAt",
    "asc",
    (row, orderByKey) => getTime(new Date(row.get(orderByKey)))
  );

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return sortedOrders.get(index);
  }

  /**
   *
   */
  function onRowClick({ rowData }: RowMouseEventHandlerParams) {
    dispatch(actionSelectOrderRow(rowData.get("id")));
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | JSX.Element[] | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("number");

    if (dataKey === "selected") {
      const selected = rowData.get("selected");

      cellContent = (
        <Checkbox
          key={`active-${rowId}`}
          color="primary"
          checked={selected}
          className={classes.selectCheckbox}
        />
      );

      cellClass = classes.selectCheckboxCell;
    }

    if (dataKey === "number") {
      cellContent = (
        <Link
          to={{
            pathname: `/order/${rowId}/`,
            state: { order: rowData.toJS() },
          }}
        >
          {rowNumber}
        </Link>
      );
    }

    if (dataKey === "driver") {
      cellContent = cellData.get("email");
    }

    if (dataKey === "address") {
      const address1 = rowData.getIn(["address", "address1"]);
      const address2 = rowData.getIn(["address", "address2"]);
      const majorIntersection = rowData.getIn(["address", "majorIntersection"]);
      const postalCode = rowData.getIn(["address", "postalCode"]);
      const city = rowData.getIn(["address", "city"]);
      const province = rowData.getIn(["address", "province"]);

      cellContent = (
        <CardContent className={classes.cardContent}>
          {address1}
          {address1 && (
            <>
              ,<br />
            </>
          )}

          {address2}
          {address2 && (
            <>
              ,<br />
            </>
          )}

          {majorIntersection}
          {majorIntersection && (
            <>
              ,<br />
            </>
          )}

          {postalCode}
          {postalCode && (
            <>
              ,<br />
            </>
          )}

          {city}
          {city && (
            <>
              ,<br />
            </>
          )}
          {province}
        </CardContent>
      );
    }

    if (dataKey === "products") {
      if (isEmpty(cellData)) {
        return {
          cellContent,
          cellClass,
        };
      }

      const returnValue: [string?] = [];

      // TODO - Figure out proper typeDef of product
      cellData.forEach((product: Map<string, string>) => {
        const name = product.get("name");
        const quantity = product.get("quantity");
        const size = product.get("size");

        returnValue.push(`${quantity} x ${name} - ${size}`);
      });

      // @ts-ignore
      cellContent = returnValue.map((product) => (
        <>
          {product}
          <br />
        </>
      ));
    }

    if (dataKey === "customer") {
      cellContent = (
        <CardContent className={classes.cardContent}>
          {`${
            rowData.getIn(["customer", "firstName"]) +
            " " +
            rowData.getIn(["customer", "lastName"])
          }`}

          {(!!rowData.getIn(["customer", "emailDisplay"]) ||
            !!rowData.getIn(["customer", "phone"])) && (
            <>
              <Divider className={classes.cardContentDivider} />

              <b>Phone:</b>
              {" " + (rowData.getIn(["customer", "phone"]) || "")}
              <br />
              <b>Email:</b>
              {" " + (rowData.getIn(["customer", "emailDisplay"]) || "")}
            </>
          )}

          {!!rowData.getIn(["customer", "notes"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              {" " + rowData.getIn(["customer", "notes"])}
            </>
          )}
        </CardContent>
      );
    }

    if (dataKey === "actions") {
      const messages = rowData.get("messages");
      let unseenMessagesCount = 0;

      if (messages && messages.size) {
        unseenMessagesCount = messages.filter(
          (message: any) => !message.get("seenByAdmin")
        ).size;
      }

      cellContent = (
        <>
          <Button
            size="small"
            disabled={false}
            disableElevation
            variant="contained"
            startIcon={<MessageIcon />}
            onClick={(e) => {
              e.stopPropagation();

              setOrderMessagesDialog({
                orderId: rowId,
              });
            }}
          >
            {unseenMessagesCount
              ? `View (${unseenMessagesCount}) / Send Messages`
              : `View / Send Messages`}
          </Button>

          <br />

          <Button
            size="small"
            disableElevation
            color="secondary"
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={() => {
              setCancelOrderDialog({
                orderNumber: rowNumber,
                orderId: rowId,
              });
            }}
          >
            Cancel Delivery
          </Button>
        </>
      );

      cellClass = classes.actionsCell;
    }

    if (dataKey === "receivedAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }


    if (dataKey === "status") {
      if (cellData == "Cancelled") {
        const cancelReason = rowData.get("cancelReason");

        cellContent = (
          <CardContent className={classes.cardContent}>
            {cellData}
  
            <Divider className={classes.cardContentDivider} />
  
            {cancelReason}
          </CardContent>
        );
      }

      if (cellData === "Claimed") {
        cellContent = (
          <CardContent 
            className={classes.cardContent} 
            style={{ 
              backgroundColor: '#31f431',
              height: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {cellData}
          </CardContent>
        );
      }
    }

    return {
      cellContent,
      cellClass,
    };
  };

  function renderTableHeader() {
    const selectedOrders = sortedOrders.filter((orderToSelect) =>
      orderToSelect.get("selected")
    );

    return (
      <>
        <Grid container spacing={2} alignItems="center" justify="flex-end">
          <Grid item>
            <Select
              native
              disableUnderline
              value={selectedDriver}
              inputProps={{
                name: "reassign",
              }}
              disabled={!selectedOrders.size}
              onChange={({ target }) =>
                setSelectedDriver(target.value as string)
              }
            >
              <option selected>Select a driver</option>
              {availableDrivers.map((driver: any, index: number) => (
                <option
                  value={driver.get("id")}
                  key={`${driver.get("id") + index}`}
                >
                  {driver.get("email")}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item>
            <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={handleReassign}
              startIcon={<AssignIcon />}
              disabled={!selectedOrders.size}
            >
              Re-assign
            </Button>
          </Grid>

          <Grid item>
            <KeyboardDatePicker
              disableFuture
              disableToolbar
              format="MM/dd/yyyy"
              variant="inline"
              disabled={!selectedOrders.size}
              value={selectedDeliveredDate}
              placeholder="Select delivered-at date"
              onChange={(_, value) => {
                setSelectedDeliveredDate(value as string);
              }}
              InputProps={{
                disableUnderline: true,
                "aria-label": "filterFrom",
              }}
            />
          </Grid>

          <Grid item>
            <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={handleComplete}
              startIcon={<CheckIcon />}
              disabled={!selectedOrders.size}
            >
              Mark Complete
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  /**
   *
   */
  function handleReassign() {
    if (selectedDriver) {
      const selectedOrders = sortedOrders.filter((orderToSelect) =>
        orderToSelect.get("selected")
      );

      const promises = selectedOrders.map((selectedOrder) =>
        dispatch(
          actionReassignOrderToDriver(selectedOrder.get("id"), selectedDriver)
        )
      );

      Promise.all(promises)
        .then(async () => {
          await dispatch(actionFetchDispatchDrivers());

          errorSnackbarContext.toggleAlerts("Successfully re-assigned orders.");
        })
        .catch(() => {
          errorSnackbarContext.toggleAlerts(
            "Error occurred while re-assigning orders.",
            true
          );
        });
    }
  }

  /**
   *
   */
  function handleComplete() {
    const selectedOrders = sortedOrders.filter((orderToSelect) =>
      orderToSelect.get("selected")
    );

    let zonedTime: string | Date = zonedTimeToUtc(
      new Date(),
      "America/New_York"
    );

    if (selectedDeliveredDate) {
      const now = new Date();

      zonedTime = zonedTimeToUtc(
        new Date(
          `${selectedDeliveredDate} ${now.getHours()}:${now.getMinutes()}`
        ),
        "America/New_York"
      );
    }

    const promises = selectedOrders.map((selectedOrder) =>
      dispatch(
        actionMarkOrderComplete(
          selectedOrder.get("id"),
          selectedOrder.getIn(["driver", "id"]),
          zonedTime
        )
      )
    );

    Promise.all(promises).then(
      async () => {
        errorSnackbarContext.toggleAlerts(
          "Successfully marked orders complete."
        );
      },
      () => {
        errorSnackbarContext.toggleAlerts(
          "Error occurred while marking orders complete.",
          true
        );
      }
    );
  }

  return (
    <>
      <Card
        square
        className={classes.currentlyDispatchedCard}
        style={{
          height:
            sortedOrders.size * 150 > 750
              ? "750px"
              : `${sortedOrders.size * 150 + 160}px`,
        }}
      >
        <CardHeader
          subheader="Active Orders"
          className={classes.currentlyDispatchedCardHeader}
        />

        <Table
          renderEmptyHeader
          rowGetter={rowGetter}
          onRowClick={onRowClick}
          tableHeaderRowHeight={50}
          tableHeader={renderTableHeader()}
          rowCount={sortedOrders.size}
          columns={ordersActiveTableColumns.current}
          determineCellContent={determineCellContent}
        />
      </Card>

      {!!showOrderMessagesDialog && (
        <OrderMessages
          show={!!showOrderMessagesDialog}
          orderId={showOrderMessagesDialog?.orderId}
          onClose={() => setOrderMessagesDialog(null)}
        />
      )}

      <CancelDelivery
        show={showCancelOrderDialog}
        onClose={() => setCancelOrderDialog(null)}
      />
    </>
  );
}

export default withStyles(styles)(OrdersCurrentlyDispatched);
