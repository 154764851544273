import { apiInstance } from "../../config";

const FETCH_REVIEWS_START_TYPE = "FETCH_REVIEWS_START";
const FETCH_REVIEWS_SUCCESSFUL_TYPE = "FETCH_REVIEWS_SUCCESSFUL";
const FETCH_REVIEWS_FAILED_TYPE = "FETCH_REVIEWS_FAILED";

/**
 *
 */
function actionFetchReviews() {
  return {
    types: [
      FETCH_REVIEWS_START_TYPE,
      FETCH_REVIEWS_SUCCESSFUL_TYPE,
      FETCH_REVIEWS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/review"),
  };
}

const DELETE_REVIEW_START_TYPE = "DELETE_REVIEW_START";
const DELETE_REVIEW_SUCCESSFUL_TYPE = "DELETE_REVIEW_SUCCESSFUL";
const DELETE_REVIEW_FAILED_TYPE = "DELETE_REVIEW_FAILED";

/**
 *
 */
function actionDeleteReview(reviewId: string) {
  return {
    types: [
      DELETE_REVIEW_START_TYPE,
      DELETE_REVIEW_SUCCESSFUL_TYPE,
      DELETE_REVIEW_FAILED_TYPE,
    ],
    promise: apiInstance.delete(`/review/${reviewId}`),
  };
}

export {
  actionFetchReviews,
  actionDeleteReview,
  FETCH_REVIEWS_START_TYPE,
  FETCH_REVIEWS_SUCCESSFUL_TYPE,
  FETCH_REVIEWS_FAILED_TYPE,
  DELETE_REVIEW_START_TYPE,
  DELETE_REVIEW_SUCCESSFUL_TYPE,
  DELETE_REVIEW_FAILED_TYPE,
};
