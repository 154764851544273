const SalesReportsTableColumns = [
  {
    dataKey: "number",
    label: "#",
    width: 70,
    numeric: true,
  },
  {
    dataKey: "courier",
    label: "Courier",
    width: 100,
  },
  {
    dataKey: "city",
    label: "City",
    width: 100,
  },
  {
    dataKey: "startRange",
    label: "Date Start",
    width: 200,
  },
  {
    dataKey: "endRange",
    label: "Date End",
    width: 200,
  },
  {
    dataKey: "orders",
    label: "Total Orders",
    width: 150,
  },
  {
    dataKey: "driverExport",
    label: "Drivers Export",
    width: 150,
  },
  {
    dataKey: "ordersWithCoupons",
    label: "Orders with Coupons",
    width: 150,
  },
  {
    dataKey: "download",
    label: "Download",
    width: 150,
  },
  {
    dataKey: "totalsExport",
    label: "Totals Export",
    width: 150,
  },
  {
    dataKey: "delete",
    label: "Delete",
    width: 150,
  }
];

const LabattReportsTableColumns = [
  {
    dataKey: "number",
    label: "#",
    width: 70,
    numeric: true,
  },
  {
    dataKey: "startRange",
    label: "Date Start",
    width: 200,
  },
  {
    dataKey: "endRange",
    label: "Date End",
    width: 200,
  },
  {
    dataKey: "download",
    label: "Download",
    width: 250,
  },
  {
    dataKey: "delete",
    label: "Delete",
    width: 150,
  }
];

const OrderPlacedReportsTableColumns = [
  {
    dataKey: "number",
    label: "#",
    width: 70,
    numeric: true,
  },
  {
    dataKey: "courier",
    label: "Courier",
    width: 160,
  },
  {
    dataKey: "startRange",
    label: "Date Start",
    width: 200,
  },
  {
    dataKey: "endRange",
    label: "Date End",
    width: 200,
  },
  {
    dataKey: "download",
    label: "Download",
    width: 150,
  },
  {
    dataKey: "delete",
    label: "Delete",
    width: 150,
  }
];

const DriversReportsTableColumns = [
  {
    dataKey: "firstName",
    label: "First Name",
    width: 150,
  },
  {
    dataKey: "lastName",
    label: "Last Name",
    width: 150,
  },
  {
    dataKey: "email",
    label: "Email",
    width: 200,
  },
  {
    dataKey: "download",
    label: "Download",
    width: 150,
  },
];

export {
  DriversReportsTableColumns,
  LabattReportsTableColumns,
  SalesReportsTableColumns,
  OrderPlacedReportsTableColumns,
};
