import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  FETCH_POSTALCODES_START_TYPE,
  FETCH_POSTALCODES_SUCCESSFUL_TYPE,
  FETCH_POSTALCODES_FAILED_TYPE,
} from "./actions";

const initialPostalCodesState = {
  data: List(),
  isFetchingPostalCodes: false,
};

// @ts-ignore
function reducer(state = initialPostalCodesState, { type, payload }) {
  switch (type) {
    case FETCH_POSTALCODES_START_TYPE: {
      return {
        ...state,
        isFetchingPostalCodes: true,
      };
    }

    case FETCH_POSTALCODES_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = fromJS(responseData);
      }

      return {
        ...state,
        isFetchingPostalCodes: false,
      };
    }

    case FETCH_POSTALCODES_FAILED_TYPE: {
      return {
        ...state,
        isFetchingPostalCodes: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
