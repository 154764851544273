import isEmpty from "lodash.isempty";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";

import { ErrorSnackbarContext } from "../../App";
import { ORDER_FORM_TYPE } from "../../common/constants";
import { actionSetAccountNotes } from "../../store/Accounts/actions";
import { actionFetchOrderCoupons } from "../Coupons/actions";
import { actionUpdateOrder } from "./actions";
import Order from "./Order";

interface IEditOrderProps extends RouteProps {}

/**
 *
 */
function EditOrder({ location }: IEditOrderProps) {
  const { toggleAlerts } = useContext(ErrorSnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();

  let orderToEdit;

  if (isEmpty(orderToEdit) && location && location.state) {
    // @ts-ignore
    orderToEdit = location?.state.order;
  }

  if (isEmpty(orderToEdit)) {
    return null;
  }

  const initialValues = {
    id: orderToEdit.id,
    tax: orderToEdit.tax,
    type: orderToEdit.type,
    category: orderToEdit.category,
    subtotal: orderToEdit.subTotal,
    grandTotal: orderToEdit.grandTotal,
    receivedAt: orderToEdit.receivedAt,
    address: orderToEdit.address,
    customer: orderToEdit.customer,
    customerNotes: orderToEdit.customer.notes,
    products: orderToEdit.products,
    varietyOrder: orderToEdit.varietyOrder,
    varietyItems: orderToEdit.varietyItems,
    deliveryTotal: orderToEdit.deliveryTotal,
    deliveryCharge: orderToEdit.deliveryCharge,
    paymentSurcharge: orderToEdit.paymentSurcharge,
    distanceSurcharge: orderToEdit.distanceSurcharge,
    paymentMethod: orderToEdit.paymentMethod,
    couponCode: !isEmpty(orderToEdit.discountCodes)
      ? orderToEdit.discountCodes[orderToEdit.discountCodes.length - 1]
      : {},
    extrasSurcharge: orderToEdit.extrasSurcharge,
    bothStoreSurcharge: orderToEdit.bothStoreSurcharge,
    typeSurcharge: orderToEdit.typeSurcharge,
    surchargeTotal: orderToEdit.surchargeTotal,
    discount: orderToEdit.discount,
    varietySurcharge: orderToEdit.varietySurcharge,

    senderAccount: orderToEdit.senderAccount,
    senderAddress: orderToEdit.senderAddress,

    billAccount: orderToEdit.billAccount,
    billAddress: orderToEdit.billAddress,
    externalId: orderToEdit.externalId,
  };

  function onSubmit(values: any) {
    dispatch(actionUpdateOrder(values))
      // @ts-ignore
      .then(
        () => {
          dispatch(actionFetchOrderCoupons());
          dispatch(actionSetAccountNotes(values.userId, values.customerNotes));
          toggleAlerts("Successfully updated the order.");

          history.goBack();
        },
        () => {
          toggleAlerts("Error occurred while updating the order.", true);
        }
      );
  }

  return (
    <Order
      order={orderToEdit}
      orderFormType={ORDER_FORM_TYPE.Edit}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
}

export default EditOrder;
