const ReviewsTableColumns = [
  {
    dataKey: "email",
    label: "Email",
    width: 220,
  },
  {
    dataKey: "firstName",
    label: "First Name",
    width: 150,
  },
  {
    dataKey: "lastName",
    label: "Last Name",
    width: 150,
  },
  {
    dataKey: "review",
    label: "Review",
    width: 350,
  },
  {
    dataKey: "createdAt",
    label: "Date Added",
    width: 200,
    date: true,
  },
  {
    dataKey: "delete",
    label: "Delete",
    width: 150,
  },
];

export { ReviewsTableColumns };
