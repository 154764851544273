import isEmpty from "lodash.isempty";

import { apiInstance } from "./../config";
import {
  SIGN_IN_ACCOUNT_START_TYPE,
  SIGN_IN_ACCOUNT_SUCCESSFUL_TYPE,
  SIGN_IN_ACCOUNT_FAILED_TYPE,
  SIGN_OUT_ACCOUNT_START_TYPE,
} from "./actions";

interface IAuthState {
  isAuthenticating: boolean;
}

const initialState: IAuthState = {
  isAuthenticating: false,
};

// @ts-ignore
function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_ACCOUNT_START_TYPE: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }

    case SIGN_IN_ACCOUNT_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        localStorage.setItem("x-access-token", responseData.token);
        apiInstance.defaults.headers[
          "Authorization"
        ] = `Bearer ${responseData.token}`;
      }

      return {
        ...state,
        isAuthenticating: false,
      };
    }

    case SIGN_IN_ACCOUNT_FAILED_TYPE: {
      return {
        ...state,
        isAuthenticating: false,
      };
    }

    case SIGN_OUT_ACCOUNT_START_TYPE: {
      localStorage.removeItem("x-access-token");
      apiInstance.defaults.headers["Authorization"] = ``;

      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
