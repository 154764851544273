import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { format } from "date-fns";
import { Formik } from "formik";
import debounce from "lodash.debounce";
import isEmpty from "lodash.isempty";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ErrorSnackbarContext } from "../../App";
import { ORDER_FORM_TYPE } from "../../common/constants";
import {
  actionClearSearchedAccounts,
  actionSearchAccounts,
} from "../../store/Accounts/actions";
import { AddAccount } from "../Accounts/Dialogs";
import { AddAddressDialog } from "../Address/Dialogs";
import { AddCoupon } from "../Coupons/Dialogs";
import { actionCalculateOrderCharges } from "./actions";
import OrderItems from "./OrderItems";
import styles from "./styles";
import { calculateCharges } from "./utils";

const OrderSchema = Yup.object().shape({
  tax: Yup.string().nullable(),
  type: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  customer: Yup.object().nullable().required("Required"),
  customerNotes: Yup.string().nullable(),
  address: Yup.object().nullable().required("Required"),
  subtotal: Yup.string().nullable(),
  grandTotal: Yup.string().nullable(),
  deliveryTotal: Yup.string().nullable(),
  products: Yup.array().when("category", {
    is: (categoryValue) => categoryValue === "regular",
    then: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required("Required"),
          name: Yup.string().required("Required"),
          quantity: Yup.string().required("Required"),
          notes: Yup.string().nullable(),
          totalPrice: Yup.string().nullable(),
          company: Yup.string().nullable(),
        })
      )
      .min(1)
      .required("Required"),

    otherwise: Yup.array().nullable(),
  }),
  paymentMethod: Yup.object().nullable().required("Required"),
  paymentSurcharge: Yup.string().nullable(),
  deliveryCharge: Yup.string().nullable(),
  distanceSurcharge: Yup.string().nullable(),
  varietyItems: Yup.string().nullable(),
  varietyOrder: Yup.boolean().nullable(),
  couponCode: Yup.object().nullable(),
  bothStoreSurcharge: Yup.string().nullable(),
  extrasSurcharge: Yup.string().nullable(),
  typeSurcharge: Yup.string().nullable(),
  surchargeTotal: Yup.string().nullable(),
  varietySurcharge: Yup.string().nullable(),

  billAccount: Yup.object().nullable(),
  senderAccount: Yup.object().nullable(),

  processedBy: Yup.string().nullable(),
  placedBy: Yup.string().nullable(),
  receivedAt: Yup.string().nullable(),
  externalId: Yup.string().nullable(),
});

interface OrderProps extends WithStyles<typeof styles> {
  order?: any;
  initialValues?: any;
  orderFormType: ORDER_FORM_TYPE;
  onSubmit: (values: any, cb?: () => void) => void;
}

function Order({
  classes,
  initialValues,
  order,
  orderFormType,
  onSubmit,
}: OrderProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { toggleAlerts } = useContext(ErrorSnackbarContext);

  const [showAddAccountModal, setAddAccountModal] = useState(false);
  const [showAddCouponModal, setAddCouponModal] = useState(false);
  const [showAddAddressModal, setAddAddressModal] = useState(false);

  useEffect(
    () => () => {
      dispatch(actionClearSearchedAccounts());
    },
    []
  );

  const { couponCode: initialCouponCode } = initialValues;

  const coupons = useSelector((state: any) =>
    state.coupons.data.filter(
      (coupon: any) =>
        coupon.get("active") ||
        Boolean(initialCouponCode && coupon.get("id") === initialCouponCode.id)
    )
  );

  const paymentMethods = useSelector((state: any) => state.paymentMethods.data);

  const searchedAccounts = useSelector(
    (state: any) => state.accounts.searchedData
  );

  const debounceCustomerSearchInput = debounce((value: string) => {
    dispatch(actionSearchAccounts(value));
  }, 250);

  function generateSubmitValues(values: any) {
    const {
      type,
      products,
      address,
      customer,
      billAccount,
      billAddress,
      senderAccount,
      senderAddress,
      category,
      paymentMethod,
      couponCode,
      ...restOfValues
    } = values;

    let mappedValues = {
      // @ts-ignore
      products: products.filter((product) => !!product),
      orderType: type,
      orderCategory: category,
      address: address.id,
      userId: customer.id,
      billAccount: billAccount && billAccount.id,
      billAddress: billAccount && billAddress.id,
      senderAccount: senderAccount && senderAccount.id,
      senderAddress: senderAccount && senderAddress.id,
      paymentMethod: paymentMethod.id,
      discountCodes: !isEmpty(couponCode) ? [couponCode] : [],
      ...restOfValues,
    };

    return mappedValues;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={OrderSchema}
        onSubmit={(values) => {
          let mappedValues = generateSubmitValues(values);

          if (!isEmpty(order)) {
            mappedValues = {
              ...mappedValues,
              // @ts-ignore
              orderId: initialValues.id,
            };

            onSubmit(mappedValues);
          } else {
            onSubmit(mappedValues);
          }
        }}
      >
        {({
          errors,
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          setSubmitting,
          validateForm,
          ...rest
        }) => {
          return (
            <>
              <Card square elevation={0} className={classes.header}>
                <CardHeader
                  subheader={
                    orderFormType === "create"
                      ? "Create new order"
                      : orderFormType === "edit"
                      ? "Update order"
                      : "Re-order"
                  }
                  action={
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.orderHeaderButton}
                        onClick={() => setAddAccountModal(true)}
                      >
                        Add a user account
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.orderHeaderButton}
                        onClick={() => setAddAddressModal(true)}
                      >
                        Add an address
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.orderHeaderButton}
                        onClick={() => setAddCouponModal(true)}
                      >
                        Add a coupon
                      </Button>
                    </>
                  }
                />
              </Card>

              <Card elevation={0} square>
                <CardHeader subheader="Order Details" />

                <Divider />

                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item lg={3}>
                      <Autocomplete
                        value={values.customer}
                        disableClearable
                        options={searchedAccounts.toJS()}
                        noOptionsText="Search customer"
                        getOptionLabel={(account: any) =>
                          !isEmpty(account)
                            ? `${account.firstName} - ${account.lastName} - ${account.email}`
                            : ""
                        }
                        getOptionSelected={(option: any, value: any) =>
                          option.id === value.id
                        }
                        filterOptions={(x) => x}
                        onChange={(_, searchedOption: any) => {
                          if (!isEmpty(searchedOption)) {
                            setFieldValue("customer", searchedOption);
                            setFieldValue("address", null);
                            setFieldValue(
                              "customerNotes",
                              searchedOption.notes
                            );
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors.customer}
                            helperText={errors.customer}
                            size="small"
                            label="Customer"
                            variant="outlined"
                            placeholder="Customer"
                            onFocus={({ target }) =>
                              debounceCustomerSearchInput(target.value)
                            }
                            onChange={({ target }) =>
                              debounceCustomerSearchInput(target.value)
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <Autocomplete
                        value={values.address}
                        options={
                          isEmpty(values.customer)
                            ? []
                            : values.customer.addresses || []
                        }
                        disabled={isEmpty(values.customer)}
                        getOptionSelected={(option: any, value: any) =>
                          option.id === value.id
                        }
                        getOptionLabel={(address: any) => {
                          if (isEmpty(address)) {
                            return "";
                          }

                          const {
                            address1,
                            address2,
                            majorIntersection,
                            city,
                            postalCode,
                          } = address;

                          return [
                            address1,
                            address2,
                            majorIntersection,
                            city,
                            postalCode,
                          ].join(" - ");
                        }}
                        onChange={(_, selectedOption: any) => {
                          setFieldValue("address", selectedOption);
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors.address}
                            helperText={errors.address}
                            size="small"
                            label="Address"
                            variant="outlined"
                            placeholder="Address"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <br />

                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <TextField
                        fullWidth
                        multiline
                        disabled={!values.customer}
                        rows={3}
                        label="Customer Notes"
                        placeholder="Customer Notes"
                        variant="outlined"
                        name="customerNotes"
                        value={values.customerNotes || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <br />
                  <br />

                  <Grid container spacing={2}>
                    <Grid item lg={2}>
                      <Autocomplete
                        value={values.paymentMethod}
                        options={paymentMethods.toJS()}
                        getOptionLabel={(paymentMethod: any) =>
                          !isEmpty(paymentMethod) ? `${paymentMethod.name}` : ""
                        }
                        getOptionSelected={(option: any, value: any) =>
                          option.id === value.id
                        }
                        onChange={(_, selectedOption: any) => {
                          setFieldValue("paymentMethod", selectedOption);
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors.paymentMethod}
                            helperText={errors.paymentMethod}
                            size="small"
                            label="Payment Method"
                            variant="outlined"
                            placeholder="Payment Method"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <Autocomplete
                        disableClearable
                        value={values.category}
                        options={["regular", "variety", "courier"]}
                        getOptionLabel={(category: string) => category}
                        onChange={(_, selectedOption: string) => {
                          setFieldValue("billAddress", "");
                          setFieldValue("senderAddress", "");
                          setFieldValue("category", selectedOption);
                        }}
                        getOptionSelected={(option: string, value: string) =>
                          option === value
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            disable
                            fullWidth
                            error={!!errors.category}
                            helperText={errors.category}
                            size="small"
                            label="Category"
                            variant="outlined"
                            placeholder="Category"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <Autocomplete
                        disableClearable
                        value={values.type}
                        options={[
                          "site",
                          "call",
                          "beerguy",
                          "beerxpress",
                          "hdc",
                        ]}
                        getOptionLabel={(type: string) => type}
                        onChange={(_, selectedOption: string) =>
                          setFieldValue("type", selectedOption)
                        }
                        getOptionSelected={(option: any, value: any) =>
                          option === value
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            disable
                            fullWidth
                            error={!!errors.type}
                            helperText={errors.type}
                            size="small"
                            label="Type"
                            variant="outlined"
                            placeholder="Type"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={2} alignItems="center">
                      <Autocomplete
                        value={values.couponCode}
                        options={coupons.toJS()}
                        getOptionLabel={(coupon: any) =>
                          !isEmpty(coupon) ? `${coupon.code}` : ""
                        }
                        getOptionSelected={(option: any, value: any) =>
                          option.id === value.id
                        }
                        onChange={(_, selectedOption: any) => {
                          setFieldValue("couponCode", selectedOption);
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            label="Coupon"
                            name="couponCode"
                            variant="outlined"
                            placeholder="Coupon"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Distance Surcharge"
                        size={"small"}
                        placeholder="Distance Surcharge"
                        variant="outlined"
                        name="distanceSurcharge"
                        value={values.distanceSurcharge}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Case/Bottle Surcharge"
                        size={"small"}
                        placeholder="Case/Bottle Surcharge"
                        variant="outlined"
                        name="extrasSurcharge"
                        onChange={handleChange}
                        value={values.extrasSurcharge}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Both Stores Surcharge"
                        size={"small"}
                        placeholder="Both Stores Surcharge"
                        variant="outlined"
                        name="bothStoreSurcharge"
                        onChange={handleChange}
                        value={values.bothStoreSurcharge}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Type Surcharge"
                        size={"small"}
                        placeholder="Type Surcharge"
                        variant="outlined"
                        name="typeSurcharge"
                        onChange={handleChange}
                        value={values.typeSurcharge}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Payment Surcharge"
                        size={"small"}
                        placeholder="Payment Surcharge"
                        variant="outlined"
                        name="paymentSurcharge"
                        onChange={handleChange}
                        value={values.paymentSurcharge}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Variety Surcharge"
                        size={"small"}
                        placeholder="Variety Surcharge"
                        variant="outlined"
                        name="varietySurcharge"
                        onChange={handleChange}
                        value={values.varietySurcharge}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Surcharge Total"
                        size={"small"}
                        placeholder="Surcharge Total"
                        variant="outlined"
                        name="surchargeTotal"
                        onChange={handleChange}
                        value={values.surchargeTotal}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label="Delivery Charge"
                        size={"small"}
                        placeholder="Delivery Charge"
                        variant="outlined"
                        name="deliveryCharge"
                        onChange={handleChange}
                        value={values.deliveryCharge}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Coupon Discount"
                        size="small"
                        placeholder="Coupon Discount"
                        variant="outlined"
                        name="discount"
                        value={values.discount}
                        onChange={handleChange}
                      />
                    </Grid>

                    {order && order.isExternal && (
                      <Grid item lg={2}>
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Tip"
                          size="small"
                          placeholder="Tip"
                          variant="outlined"
                          name="tip"
                          value={order ? order.tip : "0.00"}
                        />
                      </Grid>
                    )}

                    <Grid item lg={2}>
                      <TextField
                        disabled={true}
                        fullWidth
                        name="tax"
                        label="Tax"
                        size="small"
                        placeholder="Tax"
                        variant="outlined"
                        value={values.tax}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        disabled={true}
                        fullWidth
                        size="small"
                        label="Delivery Total"
                        placeholder="Delivery Total"
                        variant="outlined"
                        name="deliveryTotal"
                        value={values.deliveryTotal}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Sub Total"
                        size={"small"}
                        placeholder="Sub Total"
                        variant="outlined"
                        name="subtotal"
                        value={values.subtotal}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        disabled={true}
                        fullWidth
                        size="small"
                        label="Grand Total"
                        placeholder="Grand Total"
                        variant="outlined"
                        name="grandTotal"
                        value={values.grandTotal}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        disabled={true}
                        size={"small"}
                        label="Processed By"
                        placeholder="Processed By"
                        variant="outlined"
                        name="processedBy"
                        value={""}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        size={"small"}
                        disabled={true}
                        label="Placed By"
                        placeholder="Placed By"
                        variant="outlined"
                        name="placedBy"
                        value={""}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        size={"small"}
                        disabled={true}
                        label="Drinkrunnr ID"
                        placeholder="Drinkrunnr ID"
                        variant="outlined"
                        name="externalId"
                        value={values.externalId}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <TextField
                        disabled
                        fullWidth
                        label="Drinkrunnr Email"
                        name="externalEmail"
                        placeholder="Drinkrunnr Email"
                        size={"small"}
                        variant="outlined"
                        value={
                          values.externalId && values.customer
                            ? values.customer.emailDisplay
                            : null
                        }
                      />
                    </Grid>
                  </Grid>

                  {values.category === "courier" && (
                    <>
                      <br />
                      <br />

                      <Grid container spacing={2}>
                        <Grid item lg={3}>
                          <Autocomplete
                            value={values.billAccount}
                            options={searchedAccounts.toJS()}
                            noOptionsText="Search customer"
                            getOptionLabel={(account: any) =>
                              !isEmpty(account)
                                ? `${account.firstName} - ${account.lastName} - ${account.email}`
                                : ""
                            }
                            getOptionSelected={(option: any, value: any) =>
                              option.id === value.id
                            }
                            filterOptions={(x) => x}
                            onChange={(_, searchedOption: any) => {
                              if (!isEmpty(searchedOption)) {
                                setFieldValue("billAccount", searchedOption);
                                setFieldValue("billAddress", null);
                              }
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={!!errors.customer}
                                helperText={errors.customer}
                                size="small"
                                label="Bill Customer"
                                variant="outlined"
                                placeholder="Bill Customer"
                                onFocus={({ target }) =>
                                  debounceCustomerSearchInput(target.value)
                                }
                                onChange={({ target }) =>
                                  debounceCustomerSearchInput(target.value)
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item lg={3}>
                          <Autocomplete
                            value={values.billAddress}
                            options={
                              isEmpty(values.billAccount)
                                ? []
                                : values.billAccount.addresses || []
                            }
                            disabled={isEmpty(values.billAccount)}
                            getOptionSelected={(option: any, value: any) =>
                              option.id === value.id
                            }
                            getOptionLabel={(address: any) => {
                              if (isEmpty(address)) {
                                return "";
                              }

                              const {
                                address1,
                                address2,
                                majorIntersection,
                                city,
                                postalCode,
                              } = address;

                              return [
                                address1,
                                address2,
                                majorIntersection,
                                city,
                                postalCode,
                              ].join(" - ");
                            }}
                            onChange={(_, selectedOption: any) => {
                              setFieldValue("billAddress", selectedOption);
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address}
                                size="small"
                                label="Bill Address"
                                variant="outlined"
                                placeholder="Address"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <br />

                      <Grid container spacing={2}>
                        <Grid item lg={3}>
                          <Autocomplete
                            value={values.senderAccount}
                            options={searchedAccounts.toJS()}
                            noOptionsText="Search customer"
                            getOptionLabel={(account: any) =>
                              !isEmpty(account)
                                ? `${account.firstName} - ${account.lastName} - ${account.email}`
                                : ""
                            }
                            getOptionSelected={(option: any, value: any) =>
                              option.id === value.id
                            }
                            filterOptions={(x) => x}
                            onChange={(_, searchedOption: any) => {
                              if (!isEmpty(searchedOption)) {
                                setFieldValue("senderAccount", searchedOption);
                                setFieldValue("senderAddress", null);
                              }
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={!!errors.customer}
                                helperText={errors.customer}
                                size="small"
                                label="Sender Customer"
                                variant="outlined"
                                placeholder="Sender Customer"
                                onFocus={({ target }) =>
                                  debounceCustomerSearchInput(target.value)
                                }
                                onChange={({ target }) =>
                                  debounceCustomerSearchInput(target.value)
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item lg={3}>
                          <Autocomplete
                            value={values.senderAddress}
                            options={
                              isEmpty(values.senderAccount)
                                ? []
                                : values.senderAccount.addresses || []
                            }
                            disabled={isEmpty(values.senderAccount)}
                            getOptionSelected={(option: any, value: any) =>
                              option.id === value.id
                            }
                            getOptionLabel={(address: any) => {
                              if (isEmpty(address)) {
                                return "";
                              }

                              const {
                                address1,
                                address2,
                                majorIntersection,
                                city,
                                postalCode,
                              } = address;

                              return [
                                address1,
                                address2,
                                majorIntersection,
                                city,
                                postalCode,
                              ].join(" - ");
                            }}
                            onChange={(_, selectedOption: any) => {
                              setFieldValue("senderAddress", selectedOption);
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address}
                                size="small"
                                label="Sender Address"
                                variant="outlined"
                                placeholder="Address"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {
                    // <Grid container spacing={2}>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       name="billName"
                    //       variant="outlined"
                    //       placeholder="Bill Name"
                    //       value={values.billName}
                    //       label="Bill Name"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       value={values.billPhone}
                    //       variant="outlined"
                    //       name="billPhone"
                    //       placeholder="Bill Phone"
                    //       label="Bill Phone"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       value={values.billPhoneExt}
                    //       variant="outlined"
                    //       name="billPhoneExt"
                    //       placeholder="Bill Phone Ext"
                    //       label="Bill Phone Ext"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       value={values.billBuzzerCode}
                    //       variant="outlined"
                    //       name="billBuzzerCode"
                    //       placeholder="Bill Buzzer Code"
                    //       label="Bill Buzzer Code"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    // </Grid>
                  }

                  {
                    // <Grid container spacing={2}>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       value={values.senderName}
                    //       variant="outlined"
                    //       name="senderName"
                    //       placeholder="Sender Name"
                    //       label="Sender Name"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       name="senderPhone"
                    //       value={values.senderPhone}
                    //       variant="outlined"
                    //       placeholder="Sender Phone"
                    //       label="Sender Phone"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       name="senderPhoneExt"
                    //       value={values.senderPhoneExt}
                    //       variant="outlined"
                    //       placeholder="Sender Phone Ext"
                    //       label="Sender Phone Ext"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    //   <Grid item lg={2}>
                    //     <TextField
                    //       fullWidth
                    //       size="small"
                    //       name="senderBuzzerCode"
                    //       value={values.senderBuzzerCode}
                    //       variant="outlined"
                    //       placeholder="Sender Buzzer Code"
                    //       label="Sender Buzzer Code"
                    //       InputProps={{
                    //         "aria-label": "filterFrom",
                    //       }}
                    //       onChange={handleChange}
                    //     />
                    //   </Grid>
                    // </Grid>
                  }

                  <br />

                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.varietyOrder}
                            onChange={handleChange}
                            name="varietyOrder"
                            color="primary"
                          />
                        }
                        label="Variety Order"
                      />
                    </Grid>
                  </Grid>

                  <br />

                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        label="Variety Items"
                        placeholder="Variety Items"
                        variant="outlined"
                        name="varietyItems"
                        value={values.varietyItems}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <br />
                  <br />

                  <Grid container spacing={2}>
                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        size="small"
                        value={format(
                          new Date(values.receivedAt),
                          "MMM dd, yyyy, h:mm aaaa"
                        )}
                        disabled={true}
                        variant="outlined"
                        placeholder="Received At"
                        label="Received At"
                        InputProps={{
                          "aria-label": "filterFrom",
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card square elevation={0} className={classes.orderItems}>
                <CardHeader
                  action={
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.orderHeaderButton}
                        onClick={() =>
                          setFieldValue("products", [
                            ...(values.products || []),
                            {},
                          ])
                        }
                      >
                        Add a product
                      </Button>
                    </>
                  }
                  subheader="Order Items"
                />

                <Snackbar
                  onClose={() => rest.setErrors({})}
                  open={!values.products.length && !!errors.products}
                  autoHideDuration={3000}
                >
                  <Alert severity="error">Please add products!</Alert>
                </Snackbar>

                {!isEmpty(values.products) && (
                  <>
                    <Divider />
                    <CardContent>
                      <OrderItems
                        products={values.products}
                        handleChange={handleChange}
                        productErrors={errors.products}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </CardContent>
                  </>
                )}
              </Card>

              <Card square elevation={0} className={classes.orderItems}>
                <CardActions className={classes.orderItemsCardAction}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Go back
                  </Button>

                  <div className={classes.submitButtons}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.submitCalculateButtons}
                      onClick={() => {
                        validateForm().then((errors) => {
                          if (isEmpty(errors)) {
                            dispatch(
                              actionCalculateOrderCharges(
                                generateSubmitValues(values)
                              )
                              // @ts-ignore
                            ).then(
                              (result: any) => {
                                const { data } = result;

                                const calculatedValues = calculateCharges(data);

                                if (!isEmpty(calculatedValues)) {
                                  for (let key in calculatedValues) {
                                    // @ts-ignore
                                    setFieldValue(key, calculatedValues[key]);
                                  }
                                }
                              },
                              (error: any) => {
                                toggleAlerts(
                                  "Error occurred while validating the order.",
                                  true
                                );
                              }
                            );
                          }
                        });
                      }}
                    >
                      Calculate Charges
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </>
          );
        }}
      </Formik>

      <AddAddressDialog
        show={showAddAddressModal}
        onClose={() => setAddAddressModal(false)}
      />

      <AddCoupon
        show={showAddCouponModal}
        onClose={() => setAddCouponModal(false)}
      />

      <AddAccount
        show={showAddAccountModal}
        onClose={() => setAddAccountModal(false)}
      />
    </>
  );
}

export default withStyles(styles)(Order);
