import {
  Button,
  Card,
  CardHeader,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { List } from "immutable";
import isEmpty from "lodash.isempty";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Index, TableCellProps } from "react-virtualized";

import { Table, sortTableWithKey } from "../../common";
import { AddAddressDialog, EditAddressDialog } from "./../Address/Dialogs";
import { AccountAddressColumns } from "./helpers";
import styles from "./styles";

interface IAccountAddressesDashboardProps extends WithStyles<typeof styles> {}

function AccountAddressesDashboard({
  classes,
}: IAccountAddressesDashboardProps) {
  const history = useHistory();
  const params = useParams<{ accountId: string }>();
  const accountId = params && params.accountId;

  const accountsTableColumns = useRef(AccountAddressColumns);

  const [showAddAddressModal, setAddAddressModal] = useState(false);
  const [addressToEdit, setEditAddressDialog] = useState<{
    accountId: string;
    addressId?: string;
  }>({
    accountId,
    addressId: undefined,
  });

  const accountToEdit = useSelector(({ accounts }: any) => {
    let account;

    const matchAccountId = (account: any) => account.get("id") === accountId;

    if (accounts.searchedData.size) {
      account = accounts.searchedData.find(matchAccountId);
    }

    if (isEmpty(account)) {
      account = accounts.data.find(matchAccountId);
    }

    return account;
  });

  let addressesToRender = List();

  if (!isEmpty(accountToEdit)) {
    addressesToRender = accountToEdit.get("addresses");

    const { sortedList: sortedAddresses } = sortTableWithKey(
      addressesToRender,
      "createdAt",
      "desc",
      (row, orderByKey) => parseInt(row.get(orderByKey))
    );

    addressesToRender = sortedAddresses;
  }

  if (!addressesToRender.size) {
    history.replace("/accounts");
    return null;
  }

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return addressesToRender.get(index);
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");

    switch (dataKey) {
      case "edit": {
        cellContent = (
          <Button
            size="small"
            disableElevation
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() =>
              setEditAddressDialog((values) => ({
                ...values,
                addressId: rowId,
              }))
            }
          >
            Edit
          </Button>
        );

        break;
      }

      default:
        break;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  return (
    <>
      <Card square elevation={0} className={classes.header}>
        <CardHeader
          subheader={`${accountToEdit.get("firstName")}'s addresses`}
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAddAddressModal(true)}
              >
                Add an address
              </Button>
            </>
          }
        />
      </Card>

      <Card square className={classes.accountsCard}>
        <CardHeader
          subheader="Addresses"
          className={classes.accountsCardHeader}
        />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowGetter={rowGetter}
          tableHeaderRowHeight={50}
          columns={accountsTableColumns.current}
          /** TODO: Add support for Account searching header */
          determineCellContent={determineCellContent}
          rowCount={addressesToRender.size}
        />
      </Card>

      <AddAddressDialog
        accountId={accountId}
        show={showAddAddressModal}
        onClose={() => setAddAddressModal(false)}
      />

      {addressToEdit && addressToEdit.addressId && (
        <EditAddressDialog
          show={!!addressToEdit.addressId}
          accountId={addressToEdit.accountId}
          addressId={addressToEdit.addressId}
          onClose={() =>
            setEditAddressDialog((values) => ({
              ...values,
              addressId: undefined,
            }))
          }
        />
      )}
    </>
  );
}

export default withStyles(styles)(AccountAddressesDashboard);
