const AccountsTableColumns = [
  {
    dataKey: "email",
    label: "Email",
    width: 220,
  },
  {
    dataKey: "firstName",
    label: "First Name",
    width: 150,
  },
  {
    dataKey: "lastName",
    label: "Last Name",
    width: 150,
  },
  {
    dataKey: "phone",
    label: "Phone",
    width: 100,
  },
  {
    dataKey: "isStaff",
    label: "Is Staff",
    width: 100,
  },
  {
    dataKey: "createdAt",
    label: "Date joined",
    width: 200,
  },
  {
    dataKey: "notes",
    label: "Notes",
    width: 220,
  },
  {
    dataKey: "edit",
    label: "Edit",
    width: 100,
  },
  {
    dataKey: "addresses",
    label: "Address",
    width: 140,
  },
  {
    dataKey: "deactivate",
    label: "Deactivate",
    width: 140,
  },
];

const AccountAddressColumns = [
  {
    dataKey: "address1",
    label: "Address 1",
    width: 180,
  },
  {
    dataKey: "address2",
    label: "Address 2",
    width: 180,
  },
  {
    dataKey: "majorIntersection",
    label: "Major Intersection",
    width: 180,
  },
  {
    dataKey: "city",
    label: "City",
    width: 100,
  },
  {
    dataKey: "province",
    label: "Province",
    width: 100,
  },
  {
    dataKey: "postalCode",
    label: "Postal Code",
    width: 100,
  },
  {
    dataKey: "edit",
    label: "Edit",
    width: 100,
  },
];

export { AccountAddressColumns, AccountsTableColumns };
