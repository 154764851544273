const ProductsTableColumns = [
  {
    dataKey: "name",
    label: "Name",
    width: 350,
  },
  {
    dataKey: "company",
    label: "Parent Company",
    width: 130,
  },
  {
    dataKey: "type",
    label: "Type",
    width: 130,
  },
  {
    dataKey: "size",
    label: "Size",
    width: 130,
  },
  {
    dataKey: "producer",
    label: "Producer",
    width: 230,
  },
  {
    dataKey: "price",
    label: "Price",
    width: 70,
    numeric: true,
  },
];

export { ProductsTableColumns };
