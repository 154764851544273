const OrdersActiveColumns = [
  {
    dataKey: "selected",
    label: "",
    width: 50,
  },
  {
    dataKey: "number",
    label: "Order #",
    width: 80,
  },
  {
    dataKey: "status",
    label: "Status",
    width: 80,
  },
  {
    dataKey: "courier",
    label: "Courier",
    width: 100,
  },
  {
    dataKey: "products",
    label: "Products",
    width: 260,
  },
  {
    dataKey: "driver",
    label: "Driver",
    width: 200,
  },
  {
    dataKey: "customer",
    label: "Customer",
    width: 240,
  },
  {
    dataKey: "address",
    label: "Address",
    width: 140,
  },
  {
    dataKey: "receivedAt",
    label: "Received",
    width: 180,
    date: true,
  },
  {
    dataKey: "actions",
    label: "Actions",
    width: 210,
  },
];

const OrdersDeliveredColumns = [
  {
    dataKey: "number",
    label: "Order #",
    width: 80,
  },
  {
    dataKey: "status",
    label: "Status",
    width: 80,
  },
  {
    dataKey: "courier",
    label: "Courier",
    width: 100,
  },
  {
    dataKey: "products",
    label: "Products",
    width: 260,
  },
  {
    dataKey: "driver",
    label: "Driver",
    width: 200,
  },
  {
    dataKey: "customer",
    label: "Customer",
    width: 240,
  },
  {
    dataKey: "address",
    label: "Address",
    width: 140,
  },
  {
    dataKey: "receivedAt",
    label: "Received",
    width: 180,
  },
  {
    dataKey: "deliveredAt",
    label: "Delivered",
    width: 180,
  },
  {
    dataKey: "actions",
    label: "Actions",
    width: 210,
  },
];

export { OrdersActiveColumns, OrdersDeliveredColumns };
