import { apiInstance } from "./../config";

const SIGN_IN_ACCOUNT_START_TYPE = "SIGN_IN_ACCOUNT_START";
const SIGN_IN_ACCOUNT_SUCCESSFUL_TYPE = "SIGN_IN_ACCOUNT_SUCCESSFUL";
const SIGN_IN_ACCOUNT_FAILED_TYPE = "SIGN_IN_ACCOUNT_FAILED";

/**
 *
 */
function actionSignInAccount(email: string, password: string) {
  return {
    types: [
      SIGN_IN_ACCOUNT_START_TYPE,
      SIGN_IN_ACCOUNT_SUCCESSFUL_TYPE,
      SIGN_IN_ACCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/account/login`, {
      email,
      password,
    }),
  };
}

const SIGN_OUT_ACCOUNT_START_TYPE = "SIGN_OUT_ACCOUNT_START";

/**
 *
 */
function actionSignOutAccount() {
  return {
    type: SIGN_OUT_ACCOUNT_START_TYPE,
  };
}

export {
  actionSignInAccount,
  actionSignOutAccount,
  SIGN_IN_ACCOUNT_START_TYPE,
  SIGN_IN_ACCOUNT_SUCCESSFUL_TYPE,
  SIGN_IN_ACCOUNT_FAILED_TYPE,
  SIGN_OUT_ACCOUNT_START_TYPE,
};
