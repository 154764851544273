import { List, Map, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  FETCH_REVIEWS_START_TYPE,
  FETCH_REVIEWS_SUCCESSFUL_TYPE,
  FETCH_REVIEWS_FAILED_TYPE,
  DELETE_REVIEW_START_TYPE,
  DELETE_REVIEW_SUCCESSFUL_TYPE,
  DELETE_REVIEW_FAILED_TYPE,
} from "./actions";

interface IReviewsState {
  data: List<any>;
  isFetchingReviews: boolean;
  isDeletingReview: boolean;
}

const initialState: IReviewsState = {
  data: List(),
  isFetchingReviews: false,
  isDeletingReview: false,
};

// @ts-ignore
function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_REVIEWS_START_TYPE: {
      return {
        ...state,
        isFetchingReviews: true,
      };
    }

    case FETCH_REVIEWS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        let reviewsList = List<any>();

        responseData.forEach((review: any) => {
          reviewsList = reviewsList.push(fromJS(review));
        });

        state.data = reviewsList;
      }

      return {
        ...state,
        isFetchingReviews: false,
      };
    }

    case FETCH_REVIEWS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingReviews: false,
      };
    }

    case DELETE_REVIEW_START_TYPE: {
      return {
        ...state,
        isDeletingReview: true,
      };
    }

    case DELETE_REVIEW_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        let reviewsList = state.data;

        reviewsList = reviewsList.filter(
          (review: any) => review.get("id") !== responseData.id
        );

        state.data = reviewsList;
      }

      return {
        ...state,
        isDeletingReview: false,
      };
    }

    case DELETE_REVIEW_FAILED_TYPE: {
      return {
        ...state,
        isDeletingReview: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
