import isEmpty from "lodash.isempty";
import React, { useContext, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorSnackbarContext } from "../../App";
import { actionEditAddress } from "../../store/Addresses/actions";
import Address from "./Address";

interface IEditAddressProps {
  accountId: string;
  addressId: string;
  closeDialog?: () => void;
}

const EditAddress = forwardRef(
  ({ accountId, addressId, closeDialog }: IEditAddressProps, ref) => {
    const { toggleAlerts } = useContext(ErrorSnackbarContext);
    const dispatch = useDispatch();

    const accountToEdit = useSelector(({ accounts }: any) => {
      let account;

      const matchAccountId = (account: any) => account.get("id") === accountId;

      if (accounts.searchedData.size) {
        account = accounts.searchedData.find(matchAccountId);
      }

      if (isEmpty(account)) {
        account = accounts.data.find(matchAccountId);
      }

      return account;
    });

    if (!accountToEdit) {
      return null;
    }

    const address = accountToEdit
      .get("addresses")
      .find((address: any) => address.get("id") === addressId);

    const initialValues = {
      customer: accountToEdit.toJS(),
      address1: address.get("address1"),
      address2: address.get("address2"),
      buzzerCode: address.get("buzzerCode"),
      city: address.get("city"),
      majorIntersection: address.get("majorIntersection"),
      postalCode: address.get("postalCode"),
      province: address.get("province"),
      phone: address.get("phone"),
      phoneExt: address.get("phoneExt"),
    };

    function onSubmit(address: any, accountId: string) {
      dispatch(actionEditAddress(address, accountId))
        // @ts-ignore
        .then(
          () => {
            toggleAlerts("Successfully updated the address.", false);
            closeDialog && closeDialog();
          },
          (error: any) => {
            toggleAlerts("Error occurred while updating the address.", true);
          }
        );
    }

    return (
      <Address
        ref={ref}
        address={address}
        account={accountToEdit}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    );
  }
);

export default EditAddress;
