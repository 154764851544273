import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

// Utilities
import {
  EDIT_HOURS_START_TYPE,
  EDIT_HOURS_SUCCESSFUL_TYPE,
  EDIT_HOURS_FAILED_TYPE,
  FETCH_HOURS_START_TYPE,
  FETCH_HOURS_SUCCESSFUL_TYPE,
  FETCH_HOURS_FAILED_TYPE,
} from "./actions";
import { IStoreHours } from "./types";

interface IHoursState {
  storeHours: List<IStoreHours>;
  isFetchingHours: boolean;
  isUpdatingHours: boolean;
}

const initialState: IHoursState = {
  storeHours: List<IStoreHours>(),
  isFetchingHours: false,
  isUpdatingHours: false,
};

// @ts-ignore
function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HOURS_START_TYPE: {
      return {
        ...state,
        isFetchingHours: true,
      };
    }

    case FETCH_HOURS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData) && !isEmpty(responseData.defaultHours)) {
        const { defaultHours = [] } = responseData;

        let defaultHoursList = List<IStoreHours>();
        defaultHours.forEach((hour: IStoreHours) => {
          defaultHoursList = defaultHoursList.push(fromJS(hour));
        });

        state.storeHours = defaultHoursList;
      }

      return {
        ...state,
        isFetchingHours: false,
      };
    }

    case FETCH_HOURS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingHours: false,
      };
    }

    case EDIT_HOURS_START_TYPE: {
      return {
        ...state,
        isEditingAccount: true,
      };
    }

    case EDIT_HOURS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      // Edit hours endpoint simply returns all default hours for simplicity,
      //   which also makes sense because the hours are edited as a whole.
      if (!isEmpty(responseData) && !isEmpty(responseData.defaultHours)) {
        const { defaultHours = [] } = responseData;

        let defaultHoursList = List<IStoreHours>();
        defaultHours.forEach((hour: IStoreHours) => {
          defaultHoursList = defaultHoursList.push(fromJS(hour));
        });

        state.storeHours = defaultHoursList;
      }

      return {
        ...state,
        isUpdatingHours: false
      };
    }

    case EDIT_HOURS_FAILED_TYPE: {
      return {
        ...state,
        isEditingAccount: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
