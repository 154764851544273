import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  Clear as ClearIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Textsms as MessageIcon,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { List } from "immutable";
import isEmpty from "lodash.isempty";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Index, IndexRange, TableCellProps } from "react-virtualized";

import { ErrorSnackbarContext } from "../../App";
import { sortTableWithKey, Table } from "../../common";
import { ORDER_TYPE } from "../../common/constants";
import {
  actionClearSearchOrders,
  actionFetchOrders,
  actionSearchOrders,
} from "./../Orders/actions";
import { CancelDelivery, OrderMessages } from "./Dialogs";
import { OrdersDeliveredColumns } from "./helpers";
import styles from "./styles";

interface IDeliveredOrdersDashboardProps extends WithStyles<typeof styles> {}

function DeliveredOrdersDashboard({ classes }: IDeliveredOrdersDashboardProps) {
  const { toggleAlerts } = useContext(ErrorSnackbarContext);
  const dispatch = useDispatch();

  const ordersDeliveredTableColumns = useRef(OrdersDeliveredColumns);

  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedSearchCategory, setSelectedSearchCategory] = useState<string>(
    "phone"
  );

  const [searchDriver, setSearchDriver] = useState<string>("");
  const isDriverSearch = selectedSearchCategory === "driver";

  const [showOrderMessagesDialog, setOrderMessagesDialog] = useState<{
    orderId: string;
  } | null>(null);
  const [showCancelOrderDialog, setCancelOrderDialog] = useState<{
    orderNumber: string;
    orderId: string;
  } | null>(null);
  const [filterDatesRange, setFilterDatesRange] = useState<{
    fromDate?: string | null;
    toDate?: string | null;
  }>({
    fromDate: null,
    toDate: null,
  });

  /**
   *
   */

  const deliveredOrders: List<any> = useSelector((state: any) =>
    state.orders.data.filter(
      (order: any) => order.get("status") === "Completed"
    )
  );

  const completedOrdersDataCursor = useSelector(
    (state: any) => state.orders.completedOrdersDataCursor
  );

  const completedOrdersDataRemaining = useSelector(
    (state: any) => state.orders.completedOrdersDataRemaining
  );

  const isFetchingOrders = useSelector(
    (state: any) => state.orders.isFetchingOrders
  );

  /**
   *
   */

  const searchedOrders: List<any> = useSelector((state: any) =>
    state.orders.searchedData.filter(
      (order: any) => order.get("status") === "Completed"
    )
  );

  const completedOrdersSearchDataCursor = useSelector(
    (state: any) => state.orders.completedOrdersSearchDataCursor
  );

  const completedOrdersSearchDataRemaining = useSelector(
    (state: any) => state.orders.completedOrdersSearchDataRemaining
  );

  const isSearchingOrders = useSelector(
    (state: any) => state.orders.isSearchingOrders
  );

  let ordersToRender = deliveredOrders;

  if (searchMode && searchedOrders.size) {
    ordersToRender = searchedOrders;
  }

  const { sortedList: sortedOrders } = sortTableWithKey(ordersToRender);

  const driverAccounts = useSelector((state: any) => {
    const driversList = state.dispatch.driversData;
    if (!driversList.size) return driversList;

    return driversList.filter(
      (account: any) => account.get("email") && account.get("isDriving")
    );
  });

  /**
   * When the container gets un-mounted, clear previously searched items.
   */
  useEffect(
    () => () => {
      dispatch(actionClearSearchOrders());
    },
    []
  );

  const remainingRows = searchedOrders.size
    ? completedOrdersSearchDataRemaining
    : completedOrdersDataRemaining;

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return sortedOrders.get(index);
  }

  /**
   *
   */
  function isRowLoaded({ index }: Index) {
    return index < sortedOrders.size;
  }

  /**
   *
   */
  function loadMoreRows(params: IndexRange) {
    return isFetchingOrders || isSearchingOrders
      ? () => {}
      : searchMode
      ? dispatch(
          actionSearchOrders(
            searchTerm,
            selectedSearchCategory,
            filterDatesRange,
            ORDER_TYPE.Completed,
            completedOrdersSearchDataCursor
          )
        )
      : dispatch(
          actionFetchOrders(ORDER_TYPE.Completed, completedOrdersDataCursor)
        );
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("number");
    const isExternal = rowData.get("isExternal");

    if (dataKey === "number") {
      cellContent = (
        <Link
          to={{
            pathname: `/order/${rowId}/`,
            state: { order: rowData.toJS() },
          }}
        >
          {rowNumber}
        </Link>
      );
    }

    if (dataKey === "driver" && cellData) {
      cellContent = cellData.get("email");
    }

    if (dataKey === "status" && cellData == "Cancelled") {
      const cancelReason = rowData.get("cancelReason");

      cellContent = (
        <CardContent className={classes.cardContent}>
          {cellData}

          <Divider className={classes.cardContentDivider} />

          {cancelReason}
        </CardContent>
      );
    }

    if (dataKey === "products") {
      if (isEmpty(cellData)) {
        return {
          cellContent,
          cellClass,
        };
      }

      const returnValue: [string?] = [];

      // TODO - Figure out proper typeDef of product
      cellData.forEach((product: Map<string, string>) => {
        const name = product.get("name");
        const quantity = product.get("quantity");
        const size = product.get("size");

        returnValue.push(`${quantity} x ${name} - ${size}`);
      });

      // @ts-ignore
      cellContent = returnValue.map((product) => (
        <>
          {product}
          <br />
        </>
      ));
    }

    if (dataKey === "customer") {
      cellContent = (
        <CardContent className={classes.cardContent}>
          {`${
            rowData.getIn(["customer", "firstName"]) +
            " " +
            rowData.getIn(["customer", "lastName"])
          }`}

          {!!rowData.getIn(["customer", "phone"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              <b>Phone:</b>
              {" " + rowData.getIn(["customer", "phone"])}
              <br />
              <b>Email:</b>
              {" " + rowData.getIn(["customer", "emailDisplay"])}
            </>
          )}

          {!!rowData.getIn(["customer", "notes"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              {" " + rowData.getIn(["customer", "notes"])}
            </>
          )}
        </CardContent>
      );
    }

    if (dataKey === "address") {
      const address1 = rowData.getIn(["address", "address1"]);
      const address2 = rowData.getIn(["address", "address2"]);
      const majorIntersection = rowData.getIn(["address", "majorIntersection"]);
      const postalCode = rowData.getIn(["address", "postalCode"]);
      const city = rowData.getIn(["address", "city"]);
      const province = rowData.getIn(["address", "province"]);

      cellContent = (
        <CardContent className={classes.cardContent}>
          {address1}
          {address1 && (
            <>
              ,<br />
            </>
          )}

          {address2}
          {address2 && (
            <>
              ,<br />
            </>
          )}

          {majorIntersection}
          {majorIntersection && (
            <>
              ,<br />
            </>
          )}

          {postalCode}
          {postalCode && (
            <>
              ,<br />
            </>
          )}

          {city}
          {city && (
            <>
              ,<br />
            </>
          )}
          {province}
        </CardContent>
      );
    }

    if (dataKey === "actions") {
      const messages = rowData.get("messages");
      const containsMessages = messages && messages.size;

      let unseenMessagesCount = 0;

      if (containsMessages) {
        unseenMessagesCount = messages.filter(
          (message: any) => !message.get("seenByAdmin")
        ).size;
      }

      const hasLine = containsMessages && !isExternal;

      cellContent = (
        <>
          {containsMessages && (
            <CardContent className={classes.cardContent}>
              <Button
                size="small"
                disabled={false}
                disableElevation
                variant="contained"
                startIcon={<MessageIcon />}
                onClick={(e) => {
                  e.stopPropagation();

                  setOrderMessagesDialog({
                    orderId: rowId,
                  });
                }}
              >
                {unseenMessagesCount
                  ? `View (${unseenMessagesCount}) Messages`
                  : "View Messages"}
              </Button>
            </CardContent>
          )}

          {hasLine && <br />}

          {!isExternal && (
            <Button
              size="small"
              disableElevation
              color="secondary"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                setCancelOrderDialog({
                  orderNumber: rowNumber,
                  orderId: rowId,
                });
              }}
            >
              Cancel Delivery
            </Button>
          )}
        </>
      );

      cellClass = classes.actionsCell;
    }

    if (dataKey === "receivedAt" || dataKey === "deliveredAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "enRoute") {
      cellContent = rowData.get("count");
    }

    return {
      cellContent,
      cellClass,
    };
  };

  /**
   *
   */
  function handleSearch() {
    setSearchMode(true);

    dispatch(
      actionSearchOrders(
        searchTerm,
        selectedSearchCategory,
        filterDatesRange,
        ORDER_TYPE.Completed,
        completedOrdersSearchDataCursor
      )
      // @ts-ignore
    ).then(
      (result: any) => {
        const { data } = result;
        toggleAlerts(
          `Found ${data.orders.length} order(s) for category ${selectedSearchCategory}.`
        );
      },
      (error: any) => {
        toggleAlerts("Error occurred while searching orders.", true);
      }
    );
  }

  /**
   *
   */
  function clearSearch() {
    setSearchTerm("");
    setSearchMode(false);
    setFilterDatesRange({
      toDate: null,
      fromDate: null,
    });

    dispatch(actionClearSearchOrders());
  }

  function renderTableHeader(showSearchAndFilters: boolean) {
    return (
      <>
        <Grid container alignItems="center">
          {showSearchAndFilters && (
            <Grid item lg={9}>
              <Grid container spacing={1} alignItems="center">
                <Grid item lg={2}>
                  {isDriverSearch ? (
                    <Select
                      native
                      disableUnderline
                      value={searchDriver}
                      onChange={({ target }) => {
                        setSearchDriver(target.value as string);
                        setSearchTerm(target.value as string);
                      }}
                    >
                      <option selected>Select a driver</option>
                      {driverAccounts.map((driver: any, index: number) => (
                        <option
                          value={driver.get("id")}
                          key={`${driver.get("id") + index}`}
                        >
                          {driver.get("email")}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      fullWidth
                      value={searchTerm}
                      onChange={({ target }) =>
                        setSearchTerm(target.value as string)
                      }
                      placeholder="Search term"
                      InputProps={{
                        disableUnderline: true,
                        "aria-label": "search",
                      }}
                    />
                  )}
                </Grid>

                <Grid item lg={2}>
                  <Select
                    native
                    disableUnderline
                    value={selectedSearchCategory}
                    fullWidth
                    onChange={({ target }) => {
                      // Driver search uses ID (as search term )and should be cleared when changing filters to or from
                      if (isDriverSearch || target.value === "driver") {
                        setSearchTerm("");
                      }

                      setSelectedSearchCategory(target.value as string);
                    }}
                  >
                    <option selected value="phone">
                      Phone
                    </option>
                    <option value="number">Order Number</option>
                    <option value="address">Address</option>
                    <option value="driver">Driver</option>
                    <option value="other">Other</option>
                  </Select>
                </Grid>

                <Grid item lg={2}>
                  <KeyboardDatePicker
                    disableFuture
                    disableToolbar
                    format="MM/dd/yyyy"
                    variant="inline"
                    value={filterDatesRange.fromDate}
                    placeholder="From date"
                    onChange={(_, value) =>
                      setFilterDatesRange((currentValues) => ({
                        ...currentValues,
                        fromDate: value,
                      }))
                    }
                    InputProps={{
                      disableUnderline: true,
                      "aria-label": "filterFrom",
                    }}
                  />
                </Grid>

                <Grid item lg={2}>
                  <KeyboardDatePicker
                    disableFuture
                    disableToolbar
                    format="MM/dd/yyyy"
                    variant="inline"
                    value={filterDatesRange.toDate}
                    placeholder="To date"
                    onChange={(_, value) =>
                      setFilterDatesRange((currentValues) => ({
                        ...currentValues,
                        toDate: value,
                      }))
                    }
                    InputProps={{
                      disableUnderline: true,
                      "aria-label": "filterTo",
                    }}
                  />
                </Grid>

                <Grid item>
                  <Button
                    disableElevation
                    variant="contained"
                    startIcon={<SearchIcon />}
                    size="small"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>

                {!!searchedOrders.size && (
                  <Grid item className={classes.clearButton}>
                    <Button
                      disableElevation
                      variant="contained"
                      startIcon={<ClearIcon />}
                      size="small"
                      onClick={clearSearch}
                    >
                      Clear Search
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Card square className={classes.ordersDeliveredCard}>
        <CardHeader
          subheader="Past Delivered Orders"
          className={classes.ordersDeliveredCardHeader}
        />

        <Table
          renderEmptyHeader
          rowGetter={rowGetter}
          tableHeaderRowHeight={50}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={sortedOrders.size}
          remainingRows={remainingRows}
          tableHeader={renderTableHeader(true)}
          determineCellContent={determineCellContent}
          columns={ordersDeliveredTableColumns.current}
          isLoading={isFetchingOrders || isSearchingOrders}
        />
      </Card>

      {!!showOrderMessagesDialog && (
        <OrderMessages
          viewOnly
          show={!!showOrderMessagesDialog}
          orderId={showOrderMessagesDialog?.orderId}
          onClose={() => setOrderMessagesDialog(null)}
        />
      )}

      <CancelDelivery
        show={showCancelOrderDialog}
        onClose={() => setCancelOrderDialog(null)}
      />
    </>
  );
}

export default withStyles(styles)(DeliveredOrdersDashboard);
