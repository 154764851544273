import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    margin: theme.spacing(2),
  },
  // Table select field menu paper
  tableSelectMenuPaper: {
    maxHeight: 300,
  },
  tableCellHours: {
    width: 120,
  },
  tableFooterActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    "& > button:not(:first-of-type)": {
      marginLeft: theme.spacing(2),
    }
  },
  tableFooterError: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(3),
  }
}));

export { useStyles };
