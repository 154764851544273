import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import {
  actionClearSearchedAccounts,
  actionFetchAccounts,
} from "../../store/Accounts/actions";
import AccountAddressesDashboard from "./AccountAddressesDashboard";
import AccountsDashboard from "./AccountsDashboard";

interface IAccountsProps {}

function Accounts({}: IAccountsProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionFetchAccounts());

    return () => {
      dispatch(actionClearSearchedAccounts());
    };
  }, []);

  return (
    <>
      <Switch>
        <Route
          path="/accounts/:accountId/addresses"
          component={AccountAddressesDashboard}
        />
        <Route path="/accounts" component={AccountsDashboard} />
      </Switch>
    </>
  );
}

export default Accounts;
