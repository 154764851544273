import { Map, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import type { OrderRow } from "./types";

function determineOrderStatus(
  isActive: boolean,
  isDispatched: boolean,
  isDelivered: boolean,
  isEnRoute: boolean,
  isCancelled: boolean
) {
  // Orders can be cancelled after delivery!
  if (isCancelled) return "Cancelled";
  if (isDelivered) return "Completed";

  if (isActive && !isEnRoute) {
    return "Not Claimed";
  } else if (isActive && isDispatched && isEnRoute) {
    return "Claimed";
  } else if (!isActive && !isDispatched && !isDelivered) {
    // TODO: Examine this condition???
    return "Cancelled";
  } else {
    return "N/A";
  }
}

/**
 * Get actual email from external / Drinkrunnr order customer accounts (normal orders default to original)
 * @param   email Email address
 * @returns Non-obfuscated email address
 */
function getShortEmail(email: string | null): string | null {
  if (!email) return null;

  // Regular account emails (non-Drinkrunnr / Vtex) should not be changed
  const emailParts = email.split("@");
  const domain = emailParts[emailParts.length - 1];
  if (!domain.includes("vtex.com")) return email;

  const lastHyphenIdx = email.lastIndexOf("-");
  if (lastHyphenIdx < 0) return email;

  return email.substring(0, lastHyphenIdx);
}

function calculateCharges(chargeObject: any) {
  const {
    tax,
    total,
    subtotal,
    productSubtotal,
    totalCharged,
    typeSurcharge,
    deliveryCharge,
    extrasSurcharge,
    varietySurcharge,
    paymentSurcharge,
    distanceSurcharge,
    bothStoreSurcharge,
  } = chargeObject;

  const parsedTax = parseFloat(tax);
  const parsedSubtotal = parseFloat(subtotal || productSubtotal);
  const parsedPaymentSurcharge = parseFloat(paymentSurcharge);
  const parsedDistanceSurcharge = parseFloat(distanceSurcharge);
  const parsedDeliveryCharge = parseFloat(deliveryCharge);
  const parsedExtrasSurcharge = parseFloat(extrasSurcharge);
  const parsedBothStoreSurcharge = parseFloat(bothStoreSurcharge);
  const parsedTypeSurcharge = parseFloat(typeSurcharge);
  const parsedVarietySurcharge = parseFloat(varietySurcharge);
  const parsedTotal = parseFloat(totalCharged || total);

  let deliveryTotal: number | string = 0;

  if (parsedTotal && parsedSubtotal) {
    deliveryTotal = (parsedTotal - parsedSubtotal).toFixed(2);
  }

  const surchargeTotal =
    parsedPaymentSurcharge +
    parsedDistanceSurcharge +
    parsedExtrasSurcharge +
    parsedBothStoreSurcharge +
    parsedTypeSurcharge +
    parsedVarietySurcharge;

  return {
    deliveryTotal,
    tax: parsedTax.toFixed(2),
    subtotal: parsedSubtotal.toFixed(2),
    surchargeTotal: surchargeTotal.toFixed(2),
    grandTotal: parsedTotal.toFixed(2),
    deliveryCharge: parsedDeliveryCharge.toFixed(2),
    paymentSurcharge: parsedPaymentSurcharge.toFixed(2),
    distanceSurcharge: parsedDistanceSurcharge.toFixed(2),
    varietySurcharge: parsedVarietySurcharge.toFixed(2),
    extrasSurcharge: parsedExtrasSurcharge.toFixed(2),
    bothStoreSurcharge: parsedBothStoreSurcharge.toFixed(2),
  };
}

function createOrderFromAPIResponse(apiOrder: any) {
  const {
    tax,
    cancelledAt,
    cancelledAtDeliveryRequester,
    cancelReason,
    deliveryCharge,
    id: orderId,
    numberId,
    subtotal,
    discount,
    totalCharged,
    deliveredAt,
    receivedAt,
    orderType,
    orderAccount,
    orderAddress,
    orderBillAccount,
    orderBillAddress,
    orderSenderAccount,
    orderSenderAddress,
    orderProducts,
    orderCategory,
    orderMessages,
    orderDriver,
    orderPaymentMethod,
    orderDiscountCodes,
    isActive,
    isDispatched,
    isEnRoute,
    isExternal,
    externalId,
    tip,
    paymentSurcharge,
    distanceSurcharge,
    varietyOrder,
    varietyItems,
    varietySurcharge,
    extrasSurcharge,
    bothStoreSurcharge,
    typeSurcharge,
  } = apiOrder;

  const {
    id: addressId,
    postalCodeId,
    phone: addressPhone,
    phoneExt: addressPhoneExt,
    ...restOfAddress
  } = orderAddress;

  const mappedOrderProducts = !isEmpty(orderProducts)
    ? // @ts-ignore
      orderProducts.map(({ productName, ...rest }) => ({
        name: productName,
        ...rest,
      }))
    : [];

  const parsedTax = parseFloat(tax);
  const parsedDeliveryCharge = parseFloat(deliveryCharge);
  const parsedSubtotal = parseFloat(subtotal);
  const parsedTotalCharged = parseFloat(totalCharged);
  // NOTE: Tip currently only present on external / Drinkrunnr orders and defaults to zero regardless.
  //         Check 'isExternal' flag if only displaying tip for external / Drinkrunnr orders.
  const parsedTip = isExternal && tip ? parseFloat(tip) : 0;

  const parsedPaymentSurcharge = parseFloat(paymentSurcharge);
  const parsedDistanceSurcharge = parseFloat(distanceSurcharge);
  const parsedExtrasSurcharge = parseFloat(extrasSurcharge);
  const parsedBothStoreSurcharge = parseFloat(bothStoreSurcharge);
  const parsedTypeSurcharge = parseFloat(typeSurcharge);
  const parsedVarietySurcharge = parseFloat(varietySurcharge);

  const isCancelled = Boolean(cancelledAt) || Boolean(cancelledAtDeliveryRequester);
  const isDelivered = Boolean(deliveredAt);

  const surchargeTotal =
    parsedPaymentSurcharge +
    parsedDistanceSurcharge +
    parsedExtrasSurcharge +
    parsedBothStoreSurcharge +
    parsedTypeSurcharge +
    parsedVarietySurcharge;

  // De-obfuscate email address from external / Drinkrunnr order customer accounts
  orderAccount.emailDisplay = getShortEmail(orderAccount.email);

  const orderMap: OrderRow = fromJS({
    id: orderId,
    type: orderType,
    category: orderCategory,
    isActive,
    isEnRoute,
    discount,
    isExternal,
    externalId,
    varietyItems,
    varietyOrder,
    isDispatched,
    tax: parsedTax.toFixed(2),

    // "selected" field is used for representing
    // the whether the user has selected the row or not.
    selected: false,

    number: numberId,
    pos: "don't know",
    courier: "BD Deliveries Inc.",
    receivedAt,
    deliveredAt,
    cancelReason,
    deliveryCharge: parsedDeliveryCharge.toFixed(2),
    paymentMethodId: orderPaymentMethod.id,
    subTotal: parsedSubtotal.toFixed(2),
    tip: parsedTip.toFixed(2),
    grandTotal: parsedTotalCharged.toFixed(2),
    deliveryTotal: (totalCharged - parsedSubtotal).toFixed(2),
    paymentSurcharge: parsedPaymentSurcharge.toFixed(2),
    distanceSurcharge: parsedDistanceSurcharge.toFixed(2),
    extrasSurcharge: parsedExtrasSurcharge.toFixed(2),
    bothStoreSurcharge: parsedBothStoreSurcharge.toFixed(2),
    surchargeTotal: surchargeTotal.toFixed(2),
    typeSurcharge: parsedTypeSurcharge.toFixed(2),
    varietySurcharge: parsedVarietySurcharge.toFixed(2),
    status: determineOrderStatus(
      isActive,
      isDispatched,
      isDelivered,
      isEnRoute,
      isCancelled
    ),

    discountCodes: orderDiscountCodes,
    paymentMethod: orderPaymentMethod,
    products: mappedOrderProducts,
    customer: orderAccount,
    address: orderAddress,
    messages: orderMessages,
    driver: orderDriver,

    billAccount: orderBillAccount || Map(),
    billAddress: orderBillAddress || Map(),

    senderAccount: orderSenderAccount || Map(),
    senderAddress: orderSenderAddress || Map(),
  });

  return orderMap;
}

export { createOrderFromAPIResponse, calculateCharges };
