// @ts-nocheck

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Immutable from "immutable";
import installDevTools from "immutable-devtools";

import App from "./App";
import { store } from "./store";
import * as serviceWorker from "./serviceWorker";

// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

if (process.env.NODE_ENV === "development") {
  installDevTools(Immutable);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
