import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  withStyles,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { startOfDay } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { actionSignOutAccount } from "../../Auth/actions";
import { ORDER_TYPE } from "../../common/constants";
import { actionFetchPostalCodes } from "../../store/PostalCodes/actions";
import { actionSearchProducts } from "../../store/Products/actions";
import Accounts from "../Accounts";
import CouponsDashboard from "../Coupons";
import { actionFetchOrderCoupons } from "../Coupons/actions";
import { actionFetchDispatchDrivers } from "../Dispatch/actions";
import PastOrders from "../Orders/PastOrders";
import RecentOrders from "../Orders/RecentOrders";
import { ProductsDashboard } from "../Products";
import ReviewsDashboard from "../Reviews";
import Hours from "../Hours";
import { Navigator } from "./../../common";
import { actionFetchPaymentMethods } from "./../../store/PaymentMethods/actions";
import Dispatch from "./../Dispatch";
import { CreateOrder, EditOrder, Reorder } from "./../Orders";
import { actionFetchOrders } from "./../Orders/actions";
import {
  LabattReportDashboard,
  OrderPlacedReportDashboard,
  SalesDriversReportDashboard,
  SalesReportDashboard,
} from "./../Reports";
import HomeHeader from "./Header";
import { drawerWidth, styles } from "./styles";

function HomeComponent() {
  return (
    <>
      <HomeHeader />
      <RecentOrders />
    </>
  );
}

function Home({ classes }: any) {
  const dispatch = useDispatch();
  const recentOrdersInterval = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const inActiveOrdersDataCursor = useSelector(
    (state: any) => state.orders.inActiveOrdersDataCursor
  );
  const completedOrdersDataCursor = useSelector(
    (state: any) => state.orders.completedOrdersDataCursor
  );

  useEffect(() => {
    dispatch(actionFetchPostalCodes());
    dispatch(actionFetchOrders(ORDER_TYPE.Recent, undefined, "*"));
/*     dispatch(actionFetchOrders(ORDER_TYPE.Past, undefined)); */

/*     dispatch(actionFetchOrders(ORDER_TYPE.Completed, undefined)); */
    dispatch(
      actionFetchOrders(ORDER_TYPE.Completed, undefined, "*", {
        fromDate: startOfDay(new Date()).toISOString(),
        toDate: new Date().toISOString(),
      })
    );

    dispatch(actionFetchDispatchDrivers());
    dispatch(actionSearchProducts());
    dispatch(actionFetchOrderCoupons());
    dispatch(actionFetchPaymentMethods());

    // @ts-ignore
/*     recentOrdersInterval.current = setInterval(() => {
      dispatch(actionFetchOrders(ORDER_TYPE.Recent, undefined, "*"));
      dispatch(
        actionFetchOrders(ORDER_TYPE.Completed, undefined, "*", {
          fromDate: startOfDay(new Date()).toISOString(),
          toDate: new Date().toISOString(),
        })
      );
      dispatch(actionFetchDispatchDrivers());
    }, 10 * 1000); */

/*     return () => {
      // @ts-ignore
      clearInterval(recentOrdersInterval.current);
    }; */
  }, []);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(actionSignOutAccount());
    window.location.href = "/signin";
  };

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Navigator PaperProps={{ style: { width: drawerWidth } }} />
      </nav>

      <div className={classes.app}>
        <AppBar elevation={0} position="static">
          <Toolbar className={classes.toolBar}>
            <>
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          </Toolbar>
        </AppBar>

        <main className={classes.main}>
          <Switch>
            <Route path="/accounts" component={Accounts} />
            <Route path="/coupons" component={CouponsDashboard} />
            <Route path="/dispatch" component={Dispatch} />
            <Route path="/hours" component={Hours} />
            <Route
              path="/reports/sales/drivers/:reportId"
              component={SalesDriversReportDashboard}
            />
            <Route path="/reports/sales" component={SalesReportDashboard} />
            <Route
              path="/reports/placed"
              component={OrderPlacedReportDashboard}
            />
            <Route path="/reports/labatt" component={LabattReportDashboard} />
            <Route path="/reorder/:orderId" component={Reorder} />
            <Route path="/orders/past" component={PastOrders} />
            <Route path="/order/:orderId" component={EditOrder} />
            <Route path="/order" component={CreateOrder} />
            <Route path="/products" component={ProductsDashboard} />
            <Route path="/reviews" component={ReviewsDashboard} />
            <Route path="/" component={HomeComponent} />
          </Switch>
        </main>
      </div>
    </div>
  );
}

export default withStyles(styles)(Home);
