import * as yup from "yup";
import React from "react";
import { useDispatch } from "react-redux";
import { ErrorSnackbarContext } from "../../../App";
import { Formik } from "formik";
import { actionGenerateExternalOrderReport } from "../actions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format as formatDate } from "date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";

interface GenerateExternalOrderReportProps {
  show: boolean;
  onClose: () => void;
}

const GenerateExternalOrderReportSchema = yup.object().shape({
  customDate: yup.string().nullable(),
});

const reportDateFormat = "MM-dd-yyyy";

function GenerateExternalOrderReport({
  show,
  onClose,
}: GenerateExternalOrderReportProps) {
  const dispatch = useDispatch();

  return (
    <ErrorSnackbarContext.Consumer>
      {({ toggleAlerts }) => (
        <Dialog open={show} onClose={onClose}>
          <Formik
            key="GenerateExternalOrderReport"
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={GenerateExternalOrderReportSchema}
            initialValues={{
              customDate: formatDate(new Date(), reportDateFormat),
            }}
            onSubmit={({ customDate }) => {
              dispatch(
                actionGenerateExternalOrderReport({
                  customDate: customDate,
                })
                // @ts-ignore
              ).then(
                () => {
                  toggleAlerts("Successfully generated Drinkrunnr report.");
                  onClose();
                },
                () => {
                  toggleAlerts(
                    "Error occurred while creating a Drinkrunnr report.",
                    true
                  );
                }
              );
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => {
              return (
                <>
                  <DialogTitle>Generate a Drinkrunnr report</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format={reportDateFormat}
                          variant="inline"
                          inputVariant="outlined"
                          error={!!errors.customDate}
                          helperText={errors.customDate}
                          value={values.customDate}
                          placeholder="Date"
                          onChange={(_, value) =>
                            setFieldValue("customDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterFrom",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Generate
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </ErrorSnackbarContext.Consumer>
  );
}

export default GenerateExternalOrderReport;
