import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Clear as ClearIcon, Search as SearchIcon } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCellProps } from "react-virtualized";

import { Table } from "../../common";
import { actionSearchProducts } from "../../store/Products/actions";
import { actionClearSearchProducts, actionFetchAllProducts } from "./actions";
import { ProductsTableColumns } from "./helpers";
import styles from "./styles";

interface IProductsDashboardProps extends WithStyles<typeof styles> {}

function ProductsDashboard({ classes }: IProductsDashboardProps) {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const productsTableColumns = useRef(ProductsTableColumns);

  useEffect(() => {
    dispatch(actionFetchAllProducts());
  }, []);

  const products = useSelector((state: any) => state.products.data);

  const searchedProducts = useSelector(
    (state: any) => state.products.searchedData
  );

  const productsToRender = searchedProducts.size ? searchedProducts : products;

  /**
   *
   */
  const _memoizedRowGetter = useCallback(
    ({ index }) => productsToRender.get(index),
    [productsToRender]
  );

  /**
   *
   */
  const _memoizedClearSearch = useCallback(() => {
    setSearchTerm("");
    dispatch(actionClearSearchProducts());
  }, []);

  /**
   *
   */
  const _memoizedHandleSearch = useCallback(() => {
    dispatch(actionSearchProducts(searchTerm));
  }, [searchTerm]);

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("numberId");

    if (dataKey === "company") {
      cellContent = cellData.replace(/-/gi, " ").toUpperCase();

      cellClass = classes.companyCell;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  /**
   * TODO: memoize this
   */
  const renderTableHeader = (showSearchAndFilters: boolean) => (
    <>
      <Grid container spacing={2} alignItems="center">
        {showSearchAndFilters && (
          <>
            <Grid item lg={3}>
              <TextField
                fullWidth
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
                placeholder="Search by product name and producer"
                InputProps={{
                  disableUnderline: true,
                  "aria-label": "search",
                }}
              />
            </Grid>

            <Grid item>
              <Button
                disableElevation
                variant="contained"
                startIcon={<SearchIcon />}
                size="small"
                onClick={_memoizedHandleSearch}
              >
                Search
              </Button>
            </Grid>

            {!!searchedProducts.size && (
              <Grid item>
                <Button
                  disableElevation
                  variant="contained"
                  startIcon={<ClearIcon />}
                  size="small"
                  onClick={_memoizedClearSearch}
                >
                  Clear Search
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );

  return (
    <>
      <Card square className={classes.productsCard}>
        <CardHeader
          subheader="Products"
          className={classes.productsCardHeader}
        />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowCount={productsToRender.size}
          tableHeaderRowHeight={50}
          rowGetter={_memoizedRowGetter}
          columns={productsTableColumns.current}
          tableHeader={renderTableHeader(true)}
          determineCellContent={determineCellContent}
        />
      </Card>
    </>
  );
}

export default withStyles(styles)(ProductsDashboard);
