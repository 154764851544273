const CouponsTableColumns = [
  {
    dataKey: "code",
    label: "Code",
    width: 180,
  },
  {
    dataKey: "isDisabledOnUse",
    label: "Is disabled on use",
    width: 130,
  },
  {
    dataKey: "isUsed",
    label: "Is Used",
    width: 100,
  },
  {
    dataKey: "discount",
    label: "Value",
    width: 100,
    numeric: true,
  },
  {
    dataKey: "createdAt",
    label: "Created",
    width: 200,
    date: true,
  },
];

export { CouponsTableColumns };
