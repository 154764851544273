import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { PeopleAlt as UsersIcon } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionUpdateDriverAvailability } from "./actions";
import styles from "./styles";

interface IDriversAvailability extends WithStyles<typeof styles> {}

function DriversAvailability({ classes }: IDriversAvailability) {
  const dispatch = useDispatch();

  const driversWithDispatches = useSelector(
    (state: any) => state.dispatch.driversData
  );

  if (!driversWithDispatches.size) {
    return null;
  }

  return (
    <Card square className={classes.driversCard}>
      <CardHeader
        subheader="Drivers Availability"
        className={classes.driversCardHeader}
      />

      <Divider light />

      <List className={classes.listRoot}>
        {driversWithDispatches
          .filter((driver: any) => !!driver.get("email"))
          .map((driver: any, index: number) => (
            <>
              <ListItem alignItems="flex-start" key={driver.get("id")}>
                <ListItemAvatar>
                  <Avatar>
                    <UsersIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={`${driver.get("email")} - ${driver.get(
                    "firstName"
                  )} ${driver.get("lastName")}`}
                />

                <ListItemSecondaryAction>
                  <Grid container alignItems="center">
                    <Grid item>Off Duty</Grid>
                    <Grid item className={classes.availabilityToggle}>
                      <Switch
                        edge="end"
                        color="primary"
                        onChange={() =>
                          dispatch(
                            actionUpdateDriverAvailability(
                              driver.get("id"),
                              !driver.get("isDriving")
                            )
                          )
                        }
                        checked={driver.get("isDriving")}
                      />
                    </Grid>
                    <Grid item>On Duty</Grid>
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>

              {index < driversWithDispatches.size - 1 && (
                <Divider variant="inset" component="li" />
              )}
            </>
          ))}
      </List>
    </Card>
  );
}

export default withStyles(styles)(DriversAvailability);
