import {
  Button,
  Card,
  CardHeader,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  CloudDownload as CloudDownloadIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { format, getTime } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCellProps } from "react-virtualized";

import { Table, sortTableWithKey } from "../../common";
import {
  actionDownloadReport,
  actionDeleteReport,
  actionFetchReports,
  ReportType,
} from "./actions";
import {
  AddSalesReport as AddSalesReportDialog,
  GenerateExternalOrderReport as GenerateExternalOrderReportDialog,
  GenerateSalesForCourierReport as GenerateSalesForCourierReportDialog,
} from "./Dialogs";
import { SalesReportsTableColumns } from "./helpers";
import styles from "./styles";

interface ISalesReportDashboardProps extends WithStyles<typeof styles> {}

function SalesReportDashboard({ classes }: ISalesReportDashboardProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSalesReportModal, setAddSalesReportModal] = useState(false);
  const [showExternalOrderModal, setShowExternalOrderModal] = useState(false);
  const [showCourierSalesModal, setShowCourierSalesModal] = useState(false);

  const salesReportsTableColumns = useRef(SalesReportsTableColumns);

  useEffect(() => {
    dispatch(actionFetchReports(ReportType.Sales));
  }, []);

  // @ts-ignore
  const isFetchingReports = useSelector(
    // @ts-ignore
    (state) => state.reports.isFetchingReports
  );

  // @ts-ignore
  const reports = useSelector((state) => state.reports.data);

  const { sortedList: sortedReports } = sortTableWithKey(
    reports,
    "receivedAt",
    "desc",
    (row, orderByKey) => getTime(new Date(row.get("receivedAt")))
  );

  /**
   *
   */
  const _memoizedRowGetter = useCallback(
    ({ index }) => sortedReports.get(index),
    [sortedReports]
  );

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("numberId");

    if (dataKey === "number") {
      cellContent = <span>{rowNumber}</span>;
    }

    if (dataKey === "courier") {
      cellContent = "BD";
    }

    if (dataKey === "city") {
      cellContent = rowData.get("city");
    }

    if (dataKey === "startRange") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "endRange") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "orders") {
      cellContent = rowData.get("totalOrders");
    }

    if (dataKey === "download") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            dispatch(actionDownloadReport(ReportType.Sales, rowId))
          }
        >
          Download
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    if (dataKey === "ordersWithCoupons") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            dispatch(actionDownloadReport(ReportType.Sales, rowId, true))
          }
        >
          Download
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    if (dataKey === "driverExport") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<VisibilityIcon />}
          onClick={() => history.push(`/reports/sales/drivers/${rowId}`)}
        >
          View Drivers
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    if (dataKey === "totalsExport") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            dispatch(
              actionDownloadReport(
                ReportType.Sales,
                rowId,
                undefined,
                undefined,
                true
              )
            )
          }
        >
          Download
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    if (dataKey === "delete") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() => dispatch(actionDeleteReport(ReportType.Sales, rowId))}
        >
          Delete
        </Button>
      );
      cellClass = classes.blockTableCell;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  return (
    <>
      <Card square elevation={0} className={classes.header}>
        <CardHeader
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAddSalesReportModal(true)}
              >
                Add a sales report
              </Button>
            </>
          }
        />
        <CardHeader
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setShowExternalOrderModal(true)}
              >
                Generate daily Drinkrunnr report
              </Button>
              <Button
                className={classes.ml8}
                color="primary"
                variant="contained"
                onClick={() => setShowCourierSalesModal(true)}
              >
                Generate Courier report
              </Button>
            </>
          }
        />
      </Card>

      <Card square className={classes.salesReportCard}>
        <CardHeader
          subheader="Sales reports"
          className={classes.salesReportCardHeader}
        />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowCount={reports.size}
          tableHeaderRowHeight={50}
          rowGetter={_memoizedRowGetter}
          columns={salesReportsTableColumns.current}
          determineCellContent={determineCellContent}
        />
      </Card>

      <AddSalesReportDialog
        show={showSalesReportModal}
        onClose={() => setAddSalesReportModal(false)}
      />

      <GenerateExternalOrderReportDialog
        show={showExternalOrderModal}
        onClose={() => setShowExternalOrderModal(false)}
      />

      <GenerateSalesForCourierReportDialog
        show={showCourierSalesModal}
        onClose={() => setShowCourierSalesModal(false)}
      />
    </>
  );
}

export default withStyles(styles)(SalesReportDashboard);
