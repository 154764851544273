import React, { Fragment, useCallback, useEffect, useState } from "react";
import { List } from "immutable";
import { FieldArray, Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

// Utilities
import { ErrorSnackbarContext } from "../../App";
import { actionEditHours, actionFetchHours } from "./actions";
import { dailyHoursAM, dailyHoursPM, weekDayMap } from "./helpers";

// Styles
import { useStyles } from "./styles";

const dailyHoursAMValues = dailyHoursAM.map((h) => h.value);
const dailyHoursPMValues = dailyHoursPM.map((h) => h.value);
const DefaultHoursSchema = yup.object().shape({
  hours: yup.array().of(
    yup.object().shape({
      closedAllDay: yup.bool().required(),
      endTime: yup.mixed().oneOf(dailyHoursPMValues).required(),
      startTime: yup.mixed().oneOf(dailyHoursAMValues).required(),
    })
  ),
});

const selectProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  MenuListProps: { dense: true },
};

function HoursDashboardContent(props: any) {
  return (
    <Card square className={""}>
      <CardHeader subheader="Hours" className={""} />
      <Divider />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}

function HoursDashboard() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(actionFetchHours());
  }, []);

  const isFetching: boolean = useSelector(
    // @ts-ignore
    (state) => state.hours.isFetchingHours
  );
  const isUpdating: boolean = useSelector(
    // @ts-ignore
    (state) => state.hours.isUpdatingHours
  );
  const hours: List<any> = useSelector(
    // @ts-ignore
    (state) => state.hours.storeHours
  );

  /**
   * Enable form editing
   */
  const editForm = () => {
    setIsEditing(true);
  };

  /**
   * Cancel form changes
   * @param resetForm - Reset form helper
   */
  const cancelForm = (resetForm: () => void) => {
    resetForm();
    setIsEditing(false);
  };

  /**
   * Save the form values
   * @param values       - Form values
   * @param toggleAlerts - Snackbar helper
   */
  const saveForm = async (
    values: any,
    toggleAlerts: (message: string, error: boolean) => void
  ) => {
    await dispatch(actionEditHours(values))
      // @ts-ignore
      .then(
        () => {
          toggleAlerts("Successfully updated the hours.", false);
          setIsEditing(false);
        },
        () => {
          toggleAlerts("Error occurred while updating the hours.", true);
        }
      );
  };

  if (isFetching) {
    return (
      <HoursDashboardContent>
        <Grid container justify="center">
          <Grid item>
            <CircularProgress
              className={classes.loadingIndicator}
              color="primary"
            />
          </Grid>
        </Grid>
      </HoursDashboardContent>
    );
  } else if (!hours || !hours.size) {
    return <HoursDashboardContent>No store hours</HoursDashboardContent>;
  }

  return (
    <ErrorSnackbarContext.Consumer>
      {({ toggleAlerts }) => (
        <Formik
          // Force a refresh whenever form edit state changes (enableReinitialize wasn't working...)
          // NOTE: This is a "nuclear" approach as it forces a remount of the form!
          key={isEditing.toString()}
          enableReinitialize
          initialValues={{
            hours: hours
              .map((hour: any) => ({
                id: hour.get("id"),
                dayOfWeek: weekDayMap[hour.get("dayOfWeek")],
                closedAllDay: hour.get("closedAllDay"),
                endTime: hour.get("endTime"),
                startTime: hour.get("startTime"),
              })).toJS(),
          }}
          validationSchema={DefaultHoursSchema}
          validateOnChange={false}
          onSubmit={(values) => saveForm(values, toggleAlerts)}
        >
          {({ errors, values, handleChange, handleSubmit, handleReset }) => {
            const hasErrors = Object.keys(errors).length > 0;

            return (
              <HoursDashboardContent>
                <Grid container spacing={2}>
                  <Grid item lg={6}>
                    <Table className={""} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Weekday</TableCell>
                          <TableCell className={classes.tableCellHours}>
                            Start Time
                          </TableCell>
                          <TableCell className={classes.tableCellHours}>
                            End Time
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellHours}
                          >
                            Closed all day
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.hours.map((hour: any, idx: number) => (
                          <TableRow key={hour.id}>
                            <TableCell component="th" scope="row">
                              {hour.dayOfWeek}
                            </TableCell>
                            <TableCell align="right">
                              <Select
                                disabled={!!hour.closedAllDay || isUpdating}
                                fullWidth
                                // @ts-ignore
                                MenuProps={{
                                  ...selectProps,
                                  classes: {
                                    paper: classes.tableSelectMenuPaper,
                                  },
                                }}
                                name={`hours[${idx}].startTime`}
                                readOnly={!isEditing}
                                value={hour.startTime}
                                variant="outlined"
                                onChange={handleChange}
                              >
                                {dailyHoursAM.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell align="right">
                              <Select
                                disabled={!!hour.closedAllDay || isUpdating}
                                fullWidth
                                // @ts-ignore
                                MenuProps={{
                                  ...selectProps,
                                  classes: {
                                    paper: classes.tableSelectMenuPaper,
                                  },
                                }}
                                name={`hours[${idx}].endTime`}
                                readOnly={!isEditing}
                                value={hour.endTime}
                                variant="outlined"
                                onChange={handleChange}
                              >
                                {dailyHoursPM.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCellHours}
                            >
                              <Switch
                                checked={hour.closedAllDay}
                                color="primary"
                                disabled={isUpdating}
                                name={`hours[${idx}].closedAllDay`}
                                readOnly={!isEditing}
                                // Read only does not properly prevent user input!
                                onChange={isEditing ? handleChange : undefined}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <div className={classes.tableFooterActions}>
                      {hasErrors && (
                        <div className={classes.tableFooterError}>
                          Invalid time values selected in the form
                        </div>
                      )}
                      {isEditing ? (
                        <Fragment>
                          <Button
                            disabled={isUpdating}
                            disableElevation
                            variant="contained"
                            size="small"
                            onClick={() => cancelForm(handleReset)}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            disabled={isUpdating}
                            disableElevation
                            variant="contained"
                            size="small"
                            onClick={() => handleSubmit()}
                          >
                            Update
                          </Button>
                        </Fragment>
                      ) : (
                        <Button
                          disableElevation
                          variant="contained"
                          size="small"
                          onClick={editForm}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </HoursDashboardContent>
            );
          }}
        </Formik>
      )}
    </ErrorSnackbarContext.Consumer>
  );
}

export default HoursDashboard;
