import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
    },
    blockTableCell: {
      alignItems: "flex-start",
    },
    couponsCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    couponsCardHeader: {
      backgroundColor: "white",
    },
  });

export default styles;
