import { apiInstance } from "../../config";

const CREATE_ORDER_DISCOUNT_START_TYPE = "CREATE_ORDER_DISCOUNT_START";
const CREATE_ORDER_DISCOUNT_SUCCESSFUL_TYPE =
  "CREATE_ORDER_DISCOUNT_SUCCESSFUL";
const CREATE_ORDER_DISCOUNT_FAILED_TYPE = "CREATE_ORDER_DISCOUNT_FAILED_TYPE";

/**
 *
 */
function actionAddOrderCoupon(
  code: string,
  discount: string,
  singleUse: boolean
  // expiry: any
) {
  return {
    types: [
      CREATE_ORDER_DISCOUNT_START_TYPE,
      CREATE_ORDER_DISCOUNT_SUCCESSFUL_TYPE,
      CREATE_ORDER_DISCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/admin/discount`, {
      code,
      discount,
      singleUse,
      // expiry,
    }),
  };
}

const FETCH_ORDER_DISCOUNT_START_TYPE = "FETCH_ORDER_DISCOUNT_START";
const FETCH_ORDER_DISCOUNT_SUCCESSFUL_TYPE = "FETCH_ORDER_DISCOUNT_SUCCESSFUL";
const FETCH_ORDER_DISCOUNT_FAILED_TYPE = "FETCH_ORDER_DISCOUNT_FAILED_TYPE";

/**
 *
 */
function actionFetchOrderCoupons() {
  return {
    types: [
      FETCH_ORDER_DISCOUNT_START_TYPE,
      FETCH_ORDER_DISCOUNT_SUCCESSFUL_TYPE,
      FETCH_ORDER_DISCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/discount`),
  };
}

const SEARCH_COUPONS_START_TYPE = "SEARCH_COUPONS_START";
const SEARCH_COUPONS_SUCCESSFUL_TYPE = "SEARCH_COUPONS_SUCCESSFUL";
const SEARCH_COUPONS_FAILED_TYPE = "SEARCH_COUPONS_FAILED_TYPE";

/**
 *
 */
function actionSearchCoupons(searchTerm: string, active?: boolean) {
  return {
    types: [
      SEARCH_COUPONS_START_TYPE,
      SEARCH_COUPONS_SUCCESSFUL_TYPE,
      SEARCH_COUPONS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/discount/search`, {
      params: {
        searchTerm,
        active,
      },
    }),
  };
}

const CLEAR_SEARCH_COUPONS_TYPE = "CLEAR_SEARCH_COUPONS";

/**
 *
 */
function actionClearSearchCoupons() {
  return {
    type: CLEAR_SEARCH_COUPONS_TYPE,
  };
}

export {
  actionSearchCoupons,
  actionAddOrderCoupon,
  actionClearSearchCoupons,
  actionFetchOrderCoupons,
  CLEAR_SEARCH_COUPONS_TYPE,
  CREATE_ORDER_DISCOUNT_START_TYPE,
  CREATE_ORDER_DISCOUNT_SUCCESSFUL_TYPE,
  CREATE_ORDER_DISCOUNT_FAILED_TYPE,
  FETCH_ORDER_DISCOUNT_START_TYPE,
  FETCH_ORDER_DISCOUNT_SUCCESSFUL_TYPE,
  FETCH_ORDER_DISCOUNT_FAILED_TYPE,
  SEARCH_COUPONS_START_TYPE,
  SEARCH_COUPONS_SUCCESSFUL_TYPE,
  SEARCH_COUPONS_FAILED_TYPE,
};
