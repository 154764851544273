import {
  Button,
  Card,
  CardHeader,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { VisibilityOff as VisibilityOffIcon } from "@material-ui/icons";
import { format, getTime } from "date-fns";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Index, TableCellProps } from "react-virtualized";

import { Table, sortTableWithKey } from "../../common";
import { actionDeleteReview, actionFetchReviews } from "./actions";
import { ReviewsTableColumns } from "./helpers";
import styles from "./styles";

interface IReviewsDashboardProps extends WithStyles<typeof styles> {}

function ReviewsDashboard({ classes }: IReviewsDashboardProps) {
  const dispatch = useDispatch();
  const reviewsTableColumns = useRef(ReviewsTableColumns);

  useEffect(() => {
    dispatch(actionFetchReviews());
  }, []);

  // @ts-ignore
  const reviews = useSelector((state) => state.reviews.data);

  const { sortedList: sortedReviews } = sortTableWithKey(
    reviews,
    "createdAt",
    "desc",
    (row, orderByKey) => getTime(new Date(row.get(orderByKey)))
  );

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return sortedReviews.get(index);
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");

    if (dataKey === "createdAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "delete") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<VisibilityOffIcon />}
          onClick={() => dispatch(actionDeleteReview(rowId))}
        >
          Delete Review
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  // TODO: Handle loading state
  // if (!sortedReviews.size) return null;

  return (
    <>
      <Card square className={classes.reviewsCard}>
        <CardHeader subheader="Reviews" className={classes.reviewsCardHeader} />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowGetter={rowGetter}
          tableHeaderRowHeight={50}
          rowCount={sortedReviews.size}
          columns={reviewsTableColumns.current}
          determineCellContent={determineCellContent}
        />
      </Card>
    </>
  );
}

export default withStyles(styles)(ReviewsDashboard);
