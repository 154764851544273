import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import AccountsReducer from "./Accounts/reducer";
import ProductsReducer from "./Products/reducer";
import PostalCodesReducer from "./PostalCodes/reducer";

import AuthReducer from "./../Auth/reducer";
import OrdersReducer from "../views/Orders/reducer";
import HoursReducer from "../views/Hours/reducer";
import CouponsReducer from "../views/Coupons/reducer";
import ReportsReducer from "./../views/Reports/reducer";
import DispatchReducer from "./../views/Dispatch/reducer";
import ReviewsReducer from "./../views/Reviews/reducer";
import PaymentMethodsReducer from "./PaymentMethods/reducer";

import { promiseMiddleware } from "./promiseMiddleware";

// TODO: Add prod handling
const store = createStore(
  combineReducers({
    auth: AuthReducer,
    orders: OrdersReducer,
    accounts: AccountsReducer,
    hours: HoursReducer,
    products: ProductsReducer,
    postalCodes: PostalCodesReducer,
    coupons: CouponsReducer,
    paymentMethods: PaymentMethodsReducer,
    reports: ReportsReducer,
    dispatch: DispatchReducer,
    reviews: ReviewsReducer,
  }),
  composeWithDevTools(applyMiddleware(promiseMiddleware))
);

export { store };
