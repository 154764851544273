import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Select,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  Clear as ClearIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { List, Map } from "immutable";
import isEmpty from "lodash.isempty";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Index,
  IndexRange,
  RowMouseEventHandlerParams,
  TableCellProps,
} from "react-virtualized";
import { ErrorSnackbarContext } from "../../App";

import { Table, sortTableWithKey } from "../../common";
import { ORDER_TYPE } from "../../common/constants";
import { actionFetchDispatchDrivers } from "../Dispatch/actions";
import { styles } from "../Home/styles";
import {
  actionClearSearchOrders,
  actionDispatchOrderToDriver,
  actionFetchOrders,
  actionSearchOrders,
  actionSelectOrderRow,
  actionUnSelectAllOrders,
} from "./actions";
import { CancelDelivery } from "./Dialogs";
import { RecentOrdersTableColumns } from "./helpers";

interface RecentOrdersProps extends WithStyles<typeof styles> {}

function RecentOrders({ classes }: RecentOrdersProps) {
  const { toggleAlerts } = useContext(ErrorSnackbarContext);
  const recentOrdersTableColumns = useRef(RecentOrdersTableColumns);
  const dispatch = useDispatch();

  const [selectedDriver, setSelectedDriver] = useState<string>("");
  const [selectedSearchCategory, setSelectedSearchCategory] = useState<string>(
    "phone"
  );

  const [showCancelOrderDialog, setCancelOrderDialog] = useState<{
    orderNumber: string;
    orderId: string;
  } | null>(null);

  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterDatesRange, setFilterDatesRange] = useState<{
    fromDate?: string | null;
    toDate?: string | null;
  }>({
    fromDate: null,
    toDate: null,
  });

  const orders: List<any> = useSelector((state: any) =>
    state.orders.data.filter(
      (order: any) => order.get("isActive") && !order.get("isDispatched")
    )
  );

  const activeOrdersDataCursor = useSelector(
    (state: any) => state.orders.activeOrdersDataCursor
  );

  const activeOrdersDataRemaining = useSelector(
    (state: any) => state.orders.activeOrdersDataRemaining
  );

  const isFetchingOrders = useSelector(
    (state: any) => state.orders.isFetchingOrders
  );

  /**
   *
   */

  const searchedOrders: List<any> = useSelector((state: any) =>
    state.orders.searchedData.filter(
      (order: any) => order.get("isActive") && !order.get("isDispatched")
    )
  );

  const activeOrdersSearchDataCursor = useSelector(
    (state: any) => state.orders.activeOrdersSearchDataCursor
  );

  const activeOrdersSearchDataRemaining = useSelector(
    (state: any) => state.orders.activeOrdersSearchDataRemaining
  );

  const isSearchingOrders = useSelector(
    (state: any) => state.orders.isSearchingOrders
  );

  const driversAccount = useSelector((state: any) => {
    const driversList = state.dispatch.driversData;

    if (driversList.size) {
      return driversList.filter(
        (account: any) => account.get("email") && account.get("isDriving")
      );
    }

    return driversList;
  });

  let ordersToRender = orders;

  if (searchMode && searchedOrders.size) {
    ordersToRender = searchedOrders;
  }

  const { sortedList: sortedOrders } = sortTableWithKey(ordersToRender);

  /**
   * When the container gets un-mounted, clear previously searched
   * and selecteditems.
   */

  useEffect(
    () => () => {
      dispatch(actionUnSelectAllOrders());
      dispatch(actionClearSearchOrders());
    },
    []
  );

  /**
   *
   */
  function onRowClick({ rowData }: RowMouseEventHandlerParams) {
    const isDispatched = rowData.get("isDispatched");

    if (!isDispatched) {
      dispatch(actionSelectOrderRow(rowData.get("id")));
    }
  }

  const remainingRows = searchedOrders.size
    ? activeOrdersSearchDataRemaining
    : activeOrdersDataRemaining;

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return sortedOrders.get(index);
  }

  /**
   *
   */
  function isRowLoaded({ index }: Index) {
    return index < sortedOrders.size;
  }

  /**
   *
   */
  function loadMoreRows(params: IndexRange) {
    return isFetchingOrders || isSearchingOrders
      ? () => {}
      : searchMode
      ? dispatch(
          actionSearchOrders(
            searchTerm,
            selectedSearchCategory,
            filterDatesRange,
            ORDER_TYPE.Past,
            activeOrdersSearchDataCursor
          )
        )
      : dispatch(actionFetchOrders(ORDER_TYPE.Recent, activeOrdersDataCursor));
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("number");
    const isDispatched = rowData.get("isDispatched");

    switch (dataKey) {
      case "number": {
        cellContent = (
          <Link
            to={{
              pathname: `/order/${rowId}/`,
              state: { order: rowData.toJS() },
            }}
          >
            {rowNumber}
          </Link>
        );
        break;
      }

      case "selected": {
        const selected = rowData.get("selected");

        cellContent = (
          <Checkbox
            key={`recent-${rowId}`}
            color="primary"
            checked={selected}
            disabled={isDispatched}
            className={classes.selectCheckbox}
          />
        );

        cellClass = classes.selectCheckboxCell;
        break;
      }

      case "products": {
        if (isEmpty(cellData)) {
          return {
            cellContent,
          };
        }

        const returnValue: [string?] = [];

        // TODO - Figure out proper typeDef of product
        cellData.forEach((product: Map<string, string>) => {
          const name = product.get("name");
          const quantity = product.get("quantity");
          const size = product.get("size");

          returnValue.push(`${quantity} x ${name} - ${size}`);
        });

        // @ts-ignore
        cellContent = returnValue.map((product) => (
          <>
            {product}
            <br />
          </>
        ));

        break;
      }

      case "actions": {
        const isExternal = rowData.get("isExternal");

        cellContent = (
          <Button
            size="small"
            disableElevation
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={(e) => {
              e.stopPropagation();

              setCancelOrderDialog({
                orderNumber: rowNumber,
                orderId: rowId,
              });
            }}
          >
            Cancel Delivery
          </Button>
        );

        cellClass = classes.actionsCell;

        break;
      }

      case "address": {
        const address1 = rowData.getIn(["address", "address1"]);
        const address2 = rowData.getIn(["address", "address2"]);
        const majorIntersection = rowData.getIn([
          "address",
          "majorIntersection",
        ]);
        const postalCode = rowData.getIn(["address", "postalCode"]);
        const city = rowData.getIn(["address", "city"]);
        const province = rowData.getIn(["address", "province"]);

        cellContent = (
          <CardContent className={classes.cardContent}>
            {address1}
            {address1 && (
              <>
                ,<br />
              </>
            )}

            {address2}
            {address2 && (
              <>
                ,<br />
              </>
            )}

            {majorIntersection}
            {majorIntersection && (
              <>
                ,<br />
              </>
            )}

            {postalCode}
            {postalCode && (
              <>
                ,<br />
              </>
            )}

            {city}
            {city && (
              <>
                ,<br />
              </>
            )}
            {province}
          </CardContent>
        );
        break;
      }

      case "receivedAt": {
        cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
        break;
      }

      case "subtotal": {
        cellContent = `$${cellData}`;
        break;
      }

      case "customer": {
        cellContent = (
          <CardContent className={classes.cardContent}>
            {`${
              rowData.getIn(["customer", "firstName"]) +
              " " +
              rowData.getIn(["customer", "lastName"])
            }`}

            {(!!rowData.getIn(["customer", "emailDisplay"]) ||
              !!rowData.getIn(["customer", "phone"])) && (
              <>
                <Divider className={classes.cardContentDivider} />

                <b>Phone:</b>
                {" " + (rowData.getIn(["customer", "phone"]) || "")}
                <br />
                <b>Email:</b>
                {" " + (rowData.getIn(["customer", "emailDisplay"]) || "")}
              </>
            )}

            {!!rowData.getIn(["customer", "notes"]) && (
              <>
                <Divider className={classes.cardContentDivider} />

                {" " + rowData.getIn(["customer", "notes"])}
              </>
            )}
          </CardContent>
        );
        break;
      }

      case "category/type": {
        let category = rowData.get("category");
        if (category === "restaurant") {
          category = "variety";
        }
        const type = rowData.get("type");

        cellContent = (
          <CardContent className={classes.cardContent}>
            <b>Category:</b>
            {" " + category.charAt(0).toUpperCase() + category.slice(1)}

            <Divider className={classes.cardContentDivider} />

            <b>Type:</b>
            {" " + type.charAt(0).toUpperCase() + type.slice(1)}
          </CardContent>
        );
        break;
      }

      case "total": {
        cellContent = (
          <CardContent className={classes.cardContent}>
            <b>Sub Total:</b>
            {" " + rowData.get("subTotal")}

            <Divider className={classes.cardContentDivider} />

            <b>Grand Total:</b>
            {" " + rowData.get("grandTotal")}

            <br />
            <b>Delivery:</b>
            {" " + rowData.get("deliveryCharge")}

            <br />
            <b>Tax:</b>
            {" " + rowData.get("tax")}

            {rowData.get("isExternal") && (
              <Fragment>
                <br />
                <b>Tip:</b>
                {" " + rowData.get("tip")}
              </Fragment>
            )}

            <br />
            <b>Delivery Total:</b>
            {" " + rowData.get("deliveryTotal")}

            <br />
            <b>Payment Method:</b>
            {" " + rowData.getIn(["paymentMethod", "name"])}
          </CardContent>
        );
        break;
      }

      case "isDispatched": {
        const driver = rowData.get("driver");

        if (driver) {
          cellContent = (
            <>
              Dispatched
              <br />
              {driver.get("email")}
            </>
          );
        } else {
          cellContent = "Not dispatched";
        }

        break;
      }

      case "recalculate": {
        cellContent = <Button variant="outlined">Recalculate</Button>;
        break;
      }

      case "resendEmails": {
        cellContent = <Button variant="outlined">Resend Emails</Button>;
        break;
      }

      case "status": {
        const status = rowData.get("status");

        if (status === "Claimed") {
          cellContent = (
            <CardContent 
              className={classes.cardContent} 
              style={{ 
                backgroundColor: '#31f431',
                height: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {cellData}
            </CardContent>
          );
        }
        break;
      }
    }

    return {
      cellContent,
      cellClass,
    };
  };

  /**
   *
   */
  async function handleDispatch() {
    const dispatchPromises: Promise<any>[] = [];
    const selectedOrders = sortedOrders.filter((orderToSelect) =>
      orderToSelect.get("selected")
    );

    selectedOrders.forEach((selectedOrder: any) => {
      dispatchPromises.push(
        // @ts-ignore
        dispatch(
          // @ts-ignore
          actionDispatchOrderToDriver(selectedOrder.get("id"), selectedDriver)
        )
      );
    });

    Promise.all(dispatchPromises).then(
      () => {
        dispatch(actionFetchDispatchDrivers());
        toggleAlerts("Successfully dispatched order(s).");
      },
      () => {
        toggleAlerts("Error occurred while dispatching order(s).", true);
      }
    );
  }

  /**
   *
   */
  function handleSearch() {
    setSearchMode(true);

    dispatch(
      actionSearchOrders(
        searchTerm,
        selectedSearchCategory,
        filterDatesRange,
        ORDER_TYPE.Recent
      )
      // @ts-ignore
    ).then(
      (result: any) => {
        const { data } = result;
        toggleAlerts(
          `Found ${data.orders.length} order(s) for category ${selectedSearchCategory}.`
        );
      },
      (error: any) => {
        toggleAlerts("Error occurred while searching orders.", true);
      }
    );
  }

  /**
   *
   */
  function clearSearch() {
    setSearchTerm("");
    setSearchMode(false);
    setFilterDatesRange({
      toDate: null,
      fromDate: null,
    });

    dispatch(actionClearSearchOrders());
  }

  function renderTableHeader(showSearchAndFilters: boolean) {
    const selectedOrders = sortedOrders.filter((orderToSelect) =>
      orderToSelect.get("selected")
    );

    return (
      <>
        <Grid container alignItems="center">
          {showSearchAndFilters && (
            <>
              <Grid item lg={9}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      value={searchTerm}
                      onChange={({ target }) =>
                        setSearchTerm(target.value as string)
                      }
                      placeholder="Search term"
                      InputProps={{
                        disableUnderline: true,
                        "aria-label": "search",
                      }}
                    />
                  </Grid>

                  <Grid item lg={2}>
                    <Select
                      native
                      disableUnderline
                      value={selectedSearchCategory}
                      fullWidth
                      onChange={({ target }) =>
                        setSelectedSearchCategory(target.value as string)
                      }
                    >
                      <option selected value="phone">
                        Phone
                      </option>
                      <option value="number">Order Number</option>
                      <option value="address">Address</option>
                      <option value="other">Other</option>
                    </Select>
                  </Grid>

                  <Grid item lg={2}>
                    <KeyboardDatePicker
                      disableFuture
                      disableToolbar
                      format="MM/dd/yyyy"
                      variant="inline"
                      value={filterDatesRange.fromDate}
                      placeholder="From date"
                      onChange={(_, value) =>
                        setFilterDatesRange((currentValues) => ({
                          ...currentValues,
                          fromDate: value,
                        }))
                      }
                      InputProps={{
                        disableUnderline: true,
                        "aria-label": "filterFrom",
                      }}
                    />
                  </Grid>

                  <Grid item lg={2}>
                    <KeyboardDatePicker
                      disableFuture
                      disableToolbar
                      format="MM/dd/yyyy"
                      variant="inline"
                      value={filterDatesRange.toDate}
                      placeholder="To date"
                      onChange={(_, value) =>
                        setFilterDatesRange((currentValues) => ({
                          ...currentValues,
                          toDate: value,
                        }))
                      }
                      InputProps={{
                        disableUnderline: true,
                        "aria-label": "filterTo",
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      disableElevation
                      variant="contained"
                      startIcon={<SearchIcon />}
                      size="small"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>

                  {!!searchedOrders.size && (
                    <Grid item className={classes.clearButton}>
                      <Button
                        disableElevation
                        variant="contained"
                        startIcon={<ClearIcon />}
                        size="small"
                        onClick={clearSearch}
                      >
                        Clear Search
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          <Grid item lg={3}>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
              <Grid item>
                <Select
                  native
                  disableUnderline
                  value={selectedDriver}
                  disabled={!selectedOrders.size}
                  onChange={({ target }) =>
                    setSelectedDriver(target.value as string)
                  }
                >
                  <option selected>Select a driver</option>
                  {driversAccount.map((driver: any, index: number) => (
                    <option
                      value={driver.get("id")}
                      key={`${driver.get("id") + index}`}
                    >
                      {driver.get("email")}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item>
                <Button
                  size="small"
                  disableElevation
                  variant="contained"
                  startIcon={<SendIcon />}
                  onClick={handleDispatch}
                  disabled={!selectedOrders.size}
                >
                  Dispatch
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Card square elevation={0} className={classes.recentOrderCard}>
        <CardHeader
          subheader="Recent Orders"
          className={classes.recentOrderCardHeader}
          // action={
          //   <>
          //     <Button
          //       size="small"
          //       disableElevation
          //       variant="contained"
          //       startIcon={<SendIcon />}
          //       onClick={() => {
          //         sortedOrders.forEach((order) => {
          //           const orderId = order.get("id");

          //           const firstDriver = accounts
          //             .filter((account) => account.get("driver"))
          //             .map((driver, index) => driver.get("id"))
          //             .first();

          //           dispatch(actionDispatchOrderToDriver(orderId, firstDriver));
          //         });
          //       }}
          //     >
          //       Dispatch all orders
          //     </Button>

          //     <Button
          //       size="small"
          //       disableElevation
          //       variant="contained"
          //       startIcon={<SendIcon />}
          //       onClick={() => {
          //         sortedOrders.forEach((order) => {
          //           const orderId = order.get("id");

          //           const firstDriver = accounts
          //             .filter((account) => account.get("driver"))
          //             .map((driver, index) => driver.get("id"))
          //             .first();

          //           dispatch(actionCompleteOrderForDriver(orderId, firstDriver));
          //         });
          //       }}
          //     >
          //       Complete all orders
          //     </Button>
          //   </>
          // }
        />

        <Table
          renderEmptyHeader
          rowGetter={rowGetter}
          onRowClick={onRowClick}
          tableHeaderRowHeight={50}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={sortedOrders.size}
          remainingRows={remainingRows}
          tableHeader={renderTableHeader(true)}
          columns={recentOrdersTableColumns.current}
          determineCellContent={determineCellContent}
          isLoading={isFetchingOrders || isSearchingOrders}
        />
      </Card>

      <CancelDelivery
        show={showCancelOrderDialog}
        onClose={() => setCancelOrderDialog(null)}
      />
    </>
  );
}

export default withStyles(styles)(RecentOrders);
