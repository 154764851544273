import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  CREATE_ORDER_DISCOUNT_START_TYPE,
  CREATE_ORDER_DISCOUNT_SUCCESSFUL_TYPE,
  CREATE_ORDER_DISCOUNT_FAILED_TYPE,
  FETCH_ORDER_DISCOUNT_START_TYPE,
  FETCH_ORDER_DISCOUNT_SUCCESSFUL_TYPE,
  FETCH_ORDER_DISCOUNT_FAILED_TYPE,
  SEARCH_COUPONS_START_TYPE,
  SEARCH_COUPONS_SUCCESSFUL_TYPE,
  SEARCH_COUPONS_FAILED_TYPE,
  CLEAR_SEARCH_COUPONS_TYPE,
} from "./actions";

const initialDiscountState = {
  data: List(),
  searchedData: List(),

  isCreatingCoupon: false,
  isFetchingCoupons: false,
  isSearchingCoupons: false,
};

// @ts-ignore
function reducer(state = initialDiscountState, { type, payload }) {
  switch (type) {
    case CREATE_ORDER_DISCOUNT_START_TYPE: {
      return {
        ...state,
        isCreatingCoupon: true,
      };
    }

    case CREATE_ORDER_DISCOUNT_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = state.data.push(fromJS(responseData));
      }

      return {
        ...state,
        isCreatingCoupon: false,
      };
    }

    case CREATE_ORDER_DISCOUNT_FAILED_TYPE: {
      return {
        ...state,
        isCreatingCoupon: false,
      };
    }

    case FETCH_ORDER_DISCOUNT_START_TYPE: {
      return {
        ...state,
        isFetchingCoupons: true,
      };
    }

    case FETCH_ORDER_DISCOUNT_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = fromJS(responseData);
      }

      return {
        ...state,
        isFetchingCoupons: false,
      };
    }

    case FETCH_ORDER_DISCOUNT_FAILED_TYPE: {
      return {
        ...state,
        isFetchingCoupons: false,
      };
    }

    case SEARCH_COUPONS_START_TYPE: {
      return {
        ...state,
        isSearchingCoupons: true,
      };
    }

    case SEARCH_COUPONS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.searchedData = fromJS(responseData);
      }

      return {
        ...state,
        isSearchingCoupons: false,
      };
    }

    case SEARCH_COUPONS_FAILED_TYPE: {
      return {
        ...state,
        isSearchingCoupons: false,
      };
    }

    case CLEAR_SEARCH_COUPONS_TYPE: {
      state.searchedData = List();

      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
