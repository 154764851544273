import {
  Button,
  Card,
  CardHeader,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { CloudDownload as CloudDownloadIcon } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import { format, getTime } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCellProps } from "react-virtualized";

import { Table, sortTableWithKey } from "./../../common";
import {
  actionDownloadReport,
  actionDeleteReport,
  actionFetchReports,
  ReportType,
} from "./actions";
import { AddOrderPlacedReport as AddPlacedReportDialog } from "./Dialogs";
import { OrderPlacedReportsTableColumns } from "./helpers";
import styles from "./styles";

interface OrderPlacedReportDashboardProps extends WithStyles<typeof styles> {}

function OrderPlacedReportDashboard({
  classes,
}: OrderPlacedReportDashboardProps) {
  const [showAddPlacedReportModal, setAddPlacedReportModal] = useState(false);
  const dispatch = useDispatch();

  const orderPlacedReportsTableColumns = useRef(OrderPlacedReportsTableColumns);

  useEffect(() => {
    dispatch(actionFetchReports(ReportType.Order_Placed));
  }, []);

  const isFetchingReports = useSelector(
    (state: any) => state.reports.isFetchingReports
  );

  const reports = useSelector((state: any) =>
    state.reports.data.filter(
      (report: any) => report.get("type") === ReportType.Order_Placed
    )
  );

  const { sortedList: sortedReports } = sortTableWithKey(
    reports,
    "receivedAt",
    "desc",
    (row, orderByKey) => getTime(new Date(row.get(orderByKey)))
  );

  /**
   *
   */
  const _memoizedRowGetter = useCallback(
    ({ index }) => sortedReports.get(index),
    [sortedReports]
  );

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("numberId");

    if (dataKey === "number") {
      cellContent = <span>{rowNumber}</span>;
    }

    if (dataKey === "courier") {
      cellContent = "BD Deliveries";
    }

    if (dataKey === "startRange") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "endRange") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "download") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            dispatch(actionDownloadReport(ReportType.Order_Placed, rowId))
          }
        >
          Download
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    if (dataKey === "delete") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() =>
            dispatch(actionDeleteReport(ReportType.Order_Placed, rowId))
          }
        >
          Delete
        </Button>
      );
      cellClass = classes.blockTableCell;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  return (
    <>
      <Card square elevation={0} className={classes.header}>
        <CardHeader
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAddPlacedReportModal(true)}
              >
                Add order placed report
              </Button>
            </>
          }
        />
      </Card>

      <Card square className={classes.orderPlacedReportCard}>
        <CardHeader
          subheader="Order Placed Reports"
          className={classes.orderPlacedReportCardHeader}
        />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowCount={reports.size}
          tableHeaderRowHeight={50}
          rowGetter={_memoizedRowGetter}
          columns={orderPlacedReportsTableColumns.current}
          determineCellContent={determineCellContent}
        />
      </Card>

      <AddPlacedReportDialog
        show={showAddPlacedReportModal}
        onClose={() => setAddPlacedReportModal(false)}
      />
    </>
  );
}

export default withStyles(styles)(OrderPlacedReportDashboard);
