import { apiInstance } from "../../config";

const FETCH_HOURS_START_TYPE = "FETCH_HOURS_START";
const FETCH_HOURS_SUCCESSFUL_TYPE = "FETCH_HOURS_SUCCESSFUL";
const FETCH_HOURS_FAILED_TYPE = "FETCH_HOURS_FAILED";

/**
 * Fetch the daily store hours
 */
function actionFetchHours() {
  return {
    types: [
      FETCH_HOURS_START_TYPE,
      FETCH_HOURS_SUCCESSFUL_TYPE,
      FETCH_HOURS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/admin/hours"),
  };
}

const EDIT_HOURS_START_TYPE = "EDIT_HOURS_START";
const EDIT_HOURS_SUCCESSFUL_TYPE = "EDIT_HOURS_SUCCESSFUL";
const EDIT_HOURS_FAILED_TYPE = "EDIT_HOURS_FAILED";

/**
 * Update the daily store hours
 * @param {Object} formFields - Updated store hours
 */
function actionEditHours(formFields: any) {
  return {
    types: [
      EDIT_HOURS_START_TYPE,
      EDIT_HOURS_SUCCESSFUL_TYPE,
      EDIT_HOURS_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/admin/hours`, {
      defaultHours: formFields.hours,
    }),
  };
}

export {
  actionEditHours,
  actionFetchHours,
  EDIT_HOURS_START_TYPE,
  EDIT_HOURS_SUCCESSFUL_TYPE,
  EDIT_HOURS_FAILED_TYPE,
  FETCH_HOURS_START_TYPE,
  FETCH_HOURS_SUCCESSFUL_TYPE,
  FETCH_HOURS_FAILED_TYPE,
};
