import { COMPANY } from "../../common/constants";
import { apiInstance } from "../../config";

const SEARCH_PRODUCTS_START_TYPE = "SEARCH_PRODUCTS_START";
const SEARCH_PRODUCTS_SUCCESSFUL_TYPE = "SEARCH_PRODUCTS_SUCCESSFUL";
const SEARCH_PRODUCTS_FAILED_TYPE = "SEARCH_PRODUCTS_FAILED";

/**
 *
 */
function actionSearchProducts(searchTerm?: string, company?: COMPANY) {
  return {
    types: [
      SEARCH_PRODUCTS_START_TYPE,
      SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
      SEARCH_PRODUCTS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/search/products", {
      params: {
        searchTerm,
        company,
      },
    }),
  };
}

export {
  actionSearchProducts,
  SEARCH_PRODUCTS_START_TYPE,
  SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
  SEARCH_PRODUCTS_FAILED_TYPE,
};
