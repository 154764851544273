import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
  HighlightOff as NotCheckCircleIcon,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  EmojiTransportation as AddressesIcon,
} from "@material-ui/icons";
import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Index, IndexRange, TableCellProps } from "react-virtualized";

import { Table } from "../../common";
import {
  actionClearSearchedAccounts,
  actionFetchAccounts,
  actionSearchAccounts,
} from "../../store/Accounts/actions";
import { AddAccount, DeactivateAccount, EditAccount } from "./Dialogs";
import { AccountsTableColumns } from "./helpers";
import styles from "./styles";
import { IDeactivateAccount } from "./types";

interface IAccountsDashboardProps extends WithStyles<typeof styles> {}

function AccountsDashboard({ classes }: IAccountsDashboardProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showAddAccountModal, setAddAccountModal] = useState(false);
  const accountsTableColumns = useRef(AccountsTableColumns);
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [accountToDeactivate, setDeactivateAccountDialog] = useState<IDeactivateAccount | null>(null);
  const [accountToEdit, setEditAccountDialog] = useState<{
    accountId: string | null;
  }>({
    accountId: null,
  });

  const accounts = useSelector((state: any) => state.accounts.data);

  const searchedAccounts = useSelector(
    (state: any) => state.accounts.searchedData
  );

  const isFetchingAccounts = useSelector(
    (state: any) => state.accounts.isFetchingAccounts
  );

  const accountsDataCursor = useSelector(
    (state: any) => state.accounts.accountsDataCursor
  );

  const accountsDataRemaining = useSelector(
    (state: any) => state.accounts.accountsDataRemaining
  );

  const accountsToRender = searchedAccounts.size ? searchedAccounts : accounts;

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return accountsToRender.get(index);
  }

  /**
   *
   */
  function clearSearch() {
    setSearchTerm("");
    setSearchMode(false);
    dispatch(actionClearSearchedAccounts());
  }

  /**
   *
   */
  function handleSearch() {
    setSearchMode(true);
    dispatch(actionSearchAccounts(searchTerm));
  }

  /**
   *
   */
  function isRowLoaded({ index }: Index) {
    return index < accountsToRender.size;
  }

  /**
   *
   */
  function loadMoreRows(params: IndexRange) {
    return isFetchingAccounts
      ? () => {}
      : dispatch(
          actionFetchAccounts({
            cursor: accountsDataCursor,
          })
        );
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");

    if (dataKey === "company") {
      cellContent = cellData.replace(/-/gi, " ");

      cellClass = classes.companyCell;
    }

    if (dataKey === "isStaff") {
      cellContent =
        rowData.get("admin") || rowData.get("driver") ? (
          <CheckCircleIcon />
        ) : (
          <NotCheckCircleIcon />
        );
    }

    if (dataKey === "createdAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "orderHistory") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<VisibilityIcon />}
          onClick={() => history.push(`/accounts/${rowId}/orders`)}
        >
          View Orders
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    if (dataKey === "notes") {
      cellContent = cellData;

      cellClass = classes.notesCell;
    }

    if (dataKey === "deactivate") {
      cellContent = (
        <Button
          size="small"
          disabled={rowData.get("admin") || rowData.get("driver")}
          disableElevation
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() =>
            setDeactivateAccountDialog({
              id: rowData.get("id"),
              email: rowData.get("email"),
              name: `${rowData.get("firstName")} ${rowData.get("lastName")}`.trim(),
            })
          }
        >
          Deactivate
        </Button>
      );
    }

    if (dataKey === "edit") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() =>
            setEditAccountDialog({
              accountId: rowId,
            })
          }
        >
          Edit
        </Button>
      );
    }

    if (dataKey === "addresses") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<AddressesIcon />}
          onClick={() => history.push(`/accounts/${rowId}/addresses/`)}
        >
          Addresses
        </Button>
      );
    }

    return {
      cellContent,
      cellClass,
    };
  };

  function renderTableHeader(showSearchAndFilters: boolean) {
    return (
      <>
        <Grid container spacing={2} alignItems="center">
          {showSearchAndFilters && (
            <>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  value={searchTerm}
                  onChange={({ target }) => setSearchTerm(target.value)}
                  placeholder="Search by first name, last name or email"
                  InputProps={{
                    disableUnderline: true,
                    "aria-label": "search",
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  disableElevation
                  variant="contained"
                  startIcon={<SearchIcon />}
                  size="small"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Grid>

              {!!searchedAccounts.size && (
                <Grid item>
                  <Button
                    disableElevation
                    variant="contained"
                    startIcon={<ClearIcon />}
                    size="small"
                    onClick={clearSearch}
                  >
                    Clear Search
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Card square elevation={0} className={classes.header}>
        <CardHeader
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAddAccountModal(true)}
              >
                Add a user account
              </Button>
            </>
          }
        />
      </Card>

      <Card square className={classes.accountsCard}>
        <CardHeader
          subheader="Accounts"
          className={classes.accountsCardHeader}
        />

        <Table
          renderEmptyHeader
          tableRowHeight={120}
          rowGetter={rowGetter}
          tableHeaderRowHeight={50}
          isLoading={isFetchingAccounts}
          tableHeader={renderTableHeader(true)}
          columns={accountsTableColumns.current}
          /** TODO: Add support for Account searching header */
          determineCellContent={determineCellContent}
          rowCount={accountsToRender.size}
          {...(!searchMode
            ? {
                isRowLoaded: isRowLoaded,
                loadMoreRows: loadMoreRows,
                remainingRows: accountsDataRemaining,
              }
            : {})}
        />
      </Card>

      <AddAccount
        show={showAddAccountModal}
        onClose={() => setAddAccountModal(false)}
      />

      <EditAccount
        show={Boolean(accountToEdit.accountId)}
        accountId={accountToEdit.accountId}
        onClose={() => setEditAccountDialog({ accountId: null })}
      />

      <DeactivateAccount
        account={accountToDeactivate}
        show={Boolean(accountToDeactivate)}
        onClose={() => setDeactivateAccountDialog(null)}
      />
    </>
  );
}

export default withStyles(styles)(AccountsDashboard);
