import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import { actionAddOrderCoupon } from "../actions";

interface AddCouponProps {
  show: boolean;
  onClose: () => void;
}

const AddCouponSchema = yup.object().shape({
  isDisabledOnUse: yup.boolean(),
  isUsed: yup.boolean(),
  code: yup.string().required("Required"),
  value: yup.string().required("Required"),
  expires: yup.string(),
});

function AddCoupon({ show, onClose }: AddCouponProps) {
  const dispatch = useDispatch();

  return (
    <Dialog open={show} onClose={onClose}>
      <ErrorSnackbarContext.Consumer>
        {({ toggleAlerts }) => (
          <Formik
            enableReinitialize
            initialValues={{
              isDisabledOnUse: false,
              isUsed: false,
              code: "",
              value: "",
              expires: new Date(),
            }}
            validateOnChange={false}
            validationSchema={AddCouponSchema}
            onSubmit={(values) => {
              dispatch(
                actionAddOrderCoupon(
                  values.code,
                  values.value,
                  values.isDisabledOnUse
                  // values.expires
                )
                // @ts-ignore
              ).then(
                () => {
                  toggleAlerts("Successfully created the discount coupon.");
                  onClose();
                },
                () => {
                  toggleAlerts(
                    "Error occurred while creating the discount coupon.",
                    true
                  );
                }
              );
            }}
          >
            {({ errors, values, handleSubmit, handleChange }) => {
              return (
                <>
                  <DialogTitle>Add coupon</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={12}>
                        <FormGroup row>
                          <FormControlLabel
                            label="Is disabled on use"
                            control={
                              <Checkbox
                                name="isDisabledOnUse"
                                onChange={handleChange}
                                checked={values.isDisabledOnUse}
                              />
                            }
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="Code"
                          size="small"
                          name="code"
                          error={!!errors.code}
                          helperText={errors.value}
                          placeholder="Enter code"
                          variant="outlined"
                          value={values.code}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="Value"
                          size="small"
                          name="value"
                          variant="outlined"
                          error={!!errors.value}
                          helperText={errors.value}
                          value={values.value}
                          onChange={handleChange}
                          placeholder="Enter value"
                        />
                      </Grid>

                      {/* <Grid item lg={12}>
                    <Field
                      ampm
                      fullWidth
                      disablePast
                      size="small"
                      name="expires"
                      label="Expires"
                      value={values.expires}
                      format="MM/dd/yyyy HH:mm"
                      inputVariant="outlined"
                      placeholder="Expires"
                      InputProps={{
                        "aria-label": "filterFrom",
                      }}
                      component={DateTimePicker}
                    />
                  </Grid> */}
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        )}
      </ErrorSnackbarContext.Consumer>
    </Dialog>
  );
}

export default AddCoupon;
