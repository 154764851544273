import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
  HighlightOff as NotCheckCircleIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { getTime, format } from "date-fns";
import { List } from "immutable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCellProps } from "react-virtualized";

import { Table, sortTableWithKey } from "../../common";
import {
  actionClearSearchCoupons,
  actionFetchOrderCoupons,
  actionSearchCoupons,
} from "./actions";
import { AddCoupon } from "./Dialogs";
import { CouponsTableColumns } from "./helpers";
import styles from "./style";

interface ICouponsDashboardProps extends WithStyles<typeof styles> {}

function CouponsDashboard({ classes }: ICouponsDashboardProps) {
  const dispatch = useDispatch();

  const couponsTableColumns = useRef(CouponsTableColumns);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showAddCouponDialog, setAddCouponDialog] = useState(false);

  useEffect(() => {
    dispatch(actionFetchOrderCoupons());
  }, []);

  const isFetchingCoupons = useSelector(
    (state: any) => state.coupons.isFetchingCoupons
  );

  const coupons = useSelector((state: any) => state.coupons.data);

  const searchedCoupons: List<any> = useSelector(
    (state: any) => state.coupons.searchedData
  );

  const couponsToRender = searchedCoupons.size ? searchedCoupons : coupons;

  const { sortedList: sortedCoupons } = sortTableWithKey(
    couponsToRender,
    "createdAt",
    "desc",
    (row, orderByKey) => getTime(new Date(row.get(orderByKey)))
  );

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    if (dataKey === "isDisabledOnUse") {
      cellContent = (
        <span>
          {!!rowData.get("singleUse") ? (
            <CheckCircleIcon />
          ) : (
            <NotCheckCircleIcon />
          )}
        </span>
      );
    }

    if (dataKey === "isUsed") {
      cellContent = (
        <span>
          {!!rowData.get("active") ? (
            <NotCheckCircleIcon />
          ) : (
            <CheckCircleIcon />
          )}
        </span>
      );
    }

    if (dataKey === "discount") {
      cellContent = (
        <span>{parseFloat(rowData.get("discount")).toFixed(2)}</span>
      );
    }

    if (dataKey === "createdAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    return {
      cellContent,
      cellClass,
    };
  };

  /**
   *
   */
  const _memoizedRowGetter = useCallback(
    ({ index }) => {
      if (sortedCoupons.size) {
        return sortedCoupons.get(index);
      }
    },
    [sortedCoupons]
  );

  /**
   *
   */
  const _memoizedHandleSearch = useCallback(() => {
    dispatch(actionSearchCoupons(searchTerm));
  }, [searchTerm]);

  /**
   *
   */
  const _memoizedClearSearch = useCallback(() => {
    setSearchTerm("");
    dispatch(actionClearSearchCoupons());
  }, []);

  /**
   * TODO: memoize this
   */
  const renderTableHeader = (showSearchAndFilters: boolean) => (
    <>
      <Grid container spacing={2} alignItems="center">
        {showSearchAndFilters && (
          <>
            <Grid item lg={3}>
              <TextField
                fullWidth
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
                placeholder="Search by coupon code"
                InputProps={{
                  disableUnderline: true,
                  "aria-label": "search",
                }}
              />
            </Grid>

            <Grid item>
              <Button
                disableElevation
                variant="contained"
                startIcon={<SearchIcon />}
                size="small"
                onClick={_memoizedHandleSearch}
              >
                Search
              </Button>
            </Grid>

            {!!searchedCoupons.size && (
              <Grid item>
                <Button
                  disableElevation
                  variant="contained"
                  startIcon={<ClearIcon />}
                  size="small"
                  onClick={_memoizedClearSearch}
                >
                  Clear Search
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );

  return (
    <>
      <Card square elevation={0} className={classes.header}>
        <CardHeader
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAddCouponDialog(true)}
              >
                Add a coupon
              </Button>
            </>
          }
        />
      </Card>

      <Card square className={classes.couponsCard}>
        <CardHeader subheader="Coupons" className={classes.couponsCardHeader} />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowCount={sortedCoupons.size}
          tableHeaderRowHeight={50}
          rowGetter={_memoizedRowGetter}
          columns={couponsTableColumns.current}
          tableHeader={renderTableHeader(true)}
          determineCellContent={determineCellContent}
        />
      </Card>

      <AddCoupon
        show={showAddCouponDialog}
        onClose={() => setAddCouponDialog(false)}
      />
    </>
  );
}

export default withStyles(styles)(CouponsDashboard);
